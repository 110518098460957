import React from "react";
import Home from "features/home/Home";
import Layout from "features/layout/Layout";

export default function HomePage() {
  return (
    <React.Fragment>
      <Layout>
        <Home />
      </Layout>
    </React.Fragment>
  );
}
