import React, { useEffect } from "react";
import Layout from "features/layout/Layout";
import IndividualProject from "features/project/IndividualProject";
export default function ProjectDetailPage() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <Layout>
      <IndividualProject />
    </Layout>
  );
}
