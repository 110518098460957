import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import IconButton from "@material-ui/core/IconButton";
import EditIcon from "@material-ui/icons/Edit";
import DoneIcon from "@material-ui/icons/Done";
import CancelIcon from "@material-ui/icons/Cancel";
import { database } from "config/fire-config";
import { useParams } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import DeleteIcon from "@material-ui/icons/Delete";
import BootstrapInput from "components/BootStrapInput";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "grid",
    placeItems: "center",
    margin: theme.spacing(2),
  },
  doneIcon: {
    marginLeft: theme.spacing(3),
    color: theme.palette.success.main,
  },
  cancelIcon: {
    marginLeft: theme.spacing(1),
    color: theme.palette.error.main,
  },
  deleteIcon: {
    marginLeft: theme.spacing(4),
    "&:hover": {
      color: theme.palette.error.dark,
    },
  },
  input: {
    margin: theme.spacing(1),
  },
  paraCard: {
    padding: theme.spacing(2),
    width: "60%",
    borderRadius: "10px",
    [theme.breakpoints.down("lg")]: {
      width: "70%",
    },
    [theme.breakpoints.down("md")]: {
      width: "80%",
    },
    [theme.breakpoints.down("xs")]: {
      width: "95%",
    },
  },
  controllerBox: {
    width: "60%",
    [theme.breakpoints.down("lg")]: {
      width: "70%",
    },
    [theme.breakpoints.down("md")]: {
      width: "80%",
    },
    [theme.breakpoints.down("xs")]: {
      width: "95%",
    },
  },
  editBox: {
    cursor: "pointer",
    margin: theme.spacing(1),
  },
}));
export default function SubHeadingItem(props) {
  const classes = useStyles();
  const { index, setItemLoading, item, setDeletedItem } = props;
  const [enableEdit, setEnableEdit] = useState(false);
  const [subHeading, setSubHeading] = useState("");
  const { blogId } = useParams();

  useEffect(() => {
    setSubHeading(item.value);
    if (item.value === "") {
      setEnableEdit(true);
    }
  }, [item]);

  const handleOnSaveTitleClick = () => {
    if (item) {
      setItemLoading(true);
      database
        .ref("blogs/" + blogId + "/blogItems/" + index + "/value")
        .set(subHeading)
        .then((data) => {
          setItemLoading(false);
          setEnableEdit(false);
        })
        .catch((err) => console.log("error creating item", err));
    }
  };

  return (
    <div className={classes.root}>
      {enableEdit ? (
        <React.Fragment>
          <Box
            display="flex"
            justifyContent="flex-end"
            className={classes.controllerBox}
          >
            <BootstrapInput
              placeholder="SubHeading..."
              value={subHeading}
              onChange={(e) => setSubHeading(e.target.value)}
              className={classes.paraItem}
            />

            <IconButton
              aria-label="done button"
              className={classes.doneIcon}
              onClick={handleOnSaveTitleClick}
            >
              <DoneIcon />
            </IconButton>
            <IconButton
              aria-label="cancel button"
              className={classes.cancelIcon}
              onClick={() => setEnableEdit(false)}
            >
              <CancelIcon />
            </IconButton>
            <IconButton
              aria-label="delete button"
              className={classes.deleteIcon}
              onClick={() => setDeletedItem(item)}
            >
              <DeleteIcon />
            </IconButton>
          </Box>
        </React.Fragment>
      ) : (
        <React.Fragment>
          <Paper className={classes.paraCard}>
            <Box
              display="flex"
              justifyContent="flex-end"
              className={classes.editBox}
            >
              <IconButton
                aria-label="edit button"
                onClick={() => setEnableEdit(true)}
              >
                <EditIcon color="secondary" />
              </IconButton>
            </Box>
            <Typography variant="h6" align="center">
              {subHeading}
            </Typography>
          </Paper>
        </React.Fragment>
      )}
    </div>
  );
}

SubHeadingItem.propTypes = {
  setDeletedItem: PropTypes.func.isRequired,
  setItemLoading: PropTypes.func.isRequired,
  item: PropTypes.any.isRequired,
  index: PropTypes.number.isRequired,
};
