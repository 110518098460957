import React from 'react';

//Material ui
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Grid from '@material-ui/core/Grid';
import Skeleton from '@material-ui/lab/Skeleton';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  root: {
    position: 'relative',
    padding: theme.spacing(8, 0),

    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(4, 0),
    },
    [theme.breakpoints.down('xs')]: {
      padding: theme.spacing(3, 0),
    },
  },
  grid: {
    padding: theme.spacing(2, 2),
  },
  card: {
    paddingBottom: theme.spacing(2),
  },
}));

const BlogCardSkeleton = () => {
  const classes = useStyles();

  return (
    <Grid
      container
      className={classes.root}
      direction="row"
      justify="center"
      alignItems="center"
    >
      {Array.from({ length: 3 }).map((item, index) => (
        <Grid
          item
          key={index}
          xs={12}
          sm={6}
          md={4}
          lg={3}
          className={classes.grid}
        >
          <Card className={classes.card}>
            <CardContent>
              <Skeleton
                variant="rect"
                animation="wave"
                width="100%"
                height={210}
              />

              <Skeleton height={30} width="90%" />
              <Skeleton animation="wave" height={20} width="40%" />
              <Skeleton animation="wave" height={20} width="40%" />
              <Skeleton
                animation="wave"
                height={15}
                style={{ marginBottom: 6, marginTop: 10 }}
              />
              <Skeleton
                animation="wave"
                height={15}
                style={{ marginBottom: 6 }}
              />
              <Skeleton animation="wave" height={15} />
            </CardContent>
          </Card>
        </Grid>
      ))}
    </Grid>
  );
};

export default BlogCardSkeleton;
