import React from 'react';

//Mui
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import TextareaAutosize from '@material-ui/core/TextareaAutosize';
import Button from '@material-ui/core/Button';
// import withWidth, { isWidthUp } from '@material-ui/core/withWidth';

// //Icon
// import PhoneIcon from '@material-ui/icons/Phone';
// import InstagramIcon from '@material-ui/icons/Instagram';
// import YouTubeIcon from '@material-ui/icons/YouTube';
// import FacebookIcon from '@material-ui/icons/Facebook';
// import MailOutlineIcon from '@material-ui/icons/MailOutline';
// import TelegramIcon from '@material-ui/icons/Telegram';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    backgroundColor: theme.palette.secondary.light,
    padding: theme.spacing(5, 0),
    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(2, 5),
    },
  },
  mapGrid: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    [theme.breakpoints.down('sm')]: {
      marginBottom: theme.spacing(3),
    },
  },
  img: {
    backgroundColor: 'white',
    height: 330,
    width: 380,
    [theme.breakpoints.down('sm')]: {
      height: 250,
      width: '100%',
    },
  },
  title: {
    color: theme.palette.secondary.dark,
    fontWeight: 'bold',
    marginBottom: theme.spacing(2),
  },

  subtext: {
    color: theme.palette.secondary.dark,
    objectFit: 'contain',
    fontSize: '14px',
    lineHeight: 1.3,
    paddingBottom: theme.spacing(1),
  },
  grid2: {
    width: '100%',

    marginTop: theme.spacing(2),
  },
  textfield: {
    backgroundColor: theme.palette.background.paper,
    borderRadius: '10px',
    color: theme.palette.secondary.dark,
  },

  textArea: {
    ...theme.typography.body1,
    border: 'none',
    outline: 'none',
    resize: 'none',
    width: '100%',
    marginTop: theme.spacing(2),
    borderRadius: '8px',
  },

  button: {
    borderRadius: '8px',
    color: theme.palette.primary.main,
  },
  buttontext: {
    color: '#fff',
    fontSize: '14px',
  },
}));

const UpperFooter = () => {
  const classes = useStyles();

  return (
    <Grid container className={classes.root}>
      <Grid item xs={12} md={6} align="center" className={classes.mapGrid}>
        <div className={classes.img} />
      </Grid>
      <Grid item xs={12} md={6}>
        <Grid item xs={12}>
          <Typography variant="h5" className={classes.title}>
            GET IN TOUCH
          </Typography>
        </Grid>
        <Grid item xs={12} md={9}>
          <Typography className={classes.subtext}>
            Please fill out the form below to send us an email and we will get
            back to you as soon as possible.
          </Typography>
          <Grid container className={classes.grid2}>
            <Grid
              item
              xs={6}
              md={6}
              style={{
                paddingRight: 8,
              }}
            >
              <TextField
                fullWidth
                placeholder="Name"
                margin="normal"
                variant="outlined"
                className={classes.textfield}
              />
            </Grid>
            <Grid item xs={6} md={6}>
              <TextField
                fullWidth
                placeholder="Phone"
                margin="normal"
                variant="outlined"
                className={classes.textfield}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                placeholder="Email"
                margin="normal"
                variant="outlined"
                className={classes.textfield}
              />
            </Grid>
            <Grid item xs={12}>
              <TextareaAutosize
                className={classes.textArea}
                placeholder={`  Message`}
                variant="contained"
                rows={6}
              />
            </Grid>
            <Grid item xs={12} style={{ marginTop: 10 }}>
              <Button
                variant="outlined"
                size="large"
                color="inherit"
                className={classes.button}
              >
                <Typography className={classes.buttontext}>
                  Send Message
                </Typography>
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default UpperFooter;
