import React from "react";
import Layout from "features/layout/Layout";
import About from "features/about/About";
export default function AboutPage() {
  return (
    <Layout>
      <About />
    </Layout>
  );
}
