import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import PropTypes from "prop-types";
import Title from "components/Title";
const useStyles = makeStyles((theme) => ({
  root: {
    display: "grid",
    placeItems: "center",
    margin: theme.spacing(0, 0),
    marginTop: theme.spacing(3),
  },
  para: {
    fontSize: 22,
    [theme.breakpoints.down("xs")]: {
      width: "90%",
      fontSize: 18,
    },
    [theme.breakpoints.up("sm")]: {
      width: "70%",
    },
    [theme.breakpoints.up("lg")]: {
      width: "75%",
    },
    [theme.breakpoints.up("xl")]: {
      width: "70%",
    },
  },
}));

export default function WhoWeAre(props) {
  const classes = useStyles();
  const { description } = props;

  return (
    <div className={classes.root}>
      <Box
        display="flex"
        justifyContent="center"
        className={classes.para}
        flexDirection="column"
      >
        <Title dark>Who We Are</Title>
        {description.map((par, i) => (
          <Typography
            key={i}
            align="center"
            variant="body1"
            style={{ fontSize: 22 }}
          >
            {par}
          </Typography>
        ))}
      </Box>
    </div>
  );
}

WhoWeAre.propTypes = {
  description: PropTypes.array.isRequired,
};
