import React from "react";
import Layout from "features/layout/Layout";
import Portfolio from "features/portfolio/Portfolio";

export default function AboutPage() {
  return (
    <Layout>
      <Portfolio />
    </Layout>
  );
}
