import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Grow from '@material-ui/core/Grow';
const useStyles = makeStyles((theme) => ({
  desc: {
    fontSize: '20px',
    color: '#000',
    marginBottom: theme.spacing(2),
  },
  list: {
    fontSize: 18,
    paddingRight: theme.spacing(2),
    color: theme.palette.primary.main,
    padiding: 0,
    width: '50%',
    float: 'left',
  },
  title: {
    marginBottom: theme.spacing(3),
    fontWeight: 700,
  },
}));

export default function MenuDescriptions(props) {
  const classes = useStyles();
  const { menus, currentIndex } = props;
  const [stateCurrentIndex, setStateCurrentIndex] = useState(-1);

  useEffect(() => {
    setStateCurrentIndex(-1);
    setTimeout(() => {
      setStateCurrentIndex(currentIndex);
    }, 50);
  }, [currentIndex]);
  return (
    <div>
      <Grow in={stateCurrentIndex !== -1} timeout={200}>
        <div>
          <Typography variant="body1" className={classes.desc}>
            {menus[currentIndex].description}
          </Typography>
          <ul style={{ lineHeight: '180%' }}>
            {menus[currentIndex].lists.map((listItem, index) => (
              <li key={`List-${index}`} className={classes.list}>
                <span style={{ color: '#000' }}>{listItem}</span>
              </li>
            ))}
          </ul>
        </div>
      </Grow>
    </div>
  );
}
MenuDescriptions.propTypes = {
  menus: PropTypes.array.isRequired,
  currentIndex: PropTypes.number.isRequired,
};
