import React, { useState } from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import withWidth from "@material-ui/core/withWidth";
import Title from "components/Title";
import DesktopServices from "./DesktopServices";
import ResponsiveServices from "./ResponsiveServices";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    position: "relative",
    [theme.breakpoints.up("lg")]: {
      maxHeight: "100vh",
    },
    [theme.breakpoints.up("xl")]: {
      maxHeight: "85vh",
    },
    background: "rgba(240,240,240,1)",
    [theme.breakpoints.down("md")]: {
      minHeight: "55vh",
    },
  },
  layer: {
    position: "absolute",
    width: "100%",
    filter: "grayscale(100%)",
    zIndex: 0,
    [theme.breakpoints.down("sm")]: {},
  },
  layer2: {
    background: "rgba(255,255,255,.9)",
    position: "absolute",
    width: "100%",
    minHeight: "200vh",
    zIndex: 1,
  },
  title: {
    marginBottom: theme.spacing(2),
    [theme.breakpoints.down("lg")]: {
      marginBottom: theme.spacing(2),
    },
    [theme.breakpoints.down("md")]: {
      marginBottom: theme.spacing(2),
    },
  },
  mainContent: {
    position: "relative",
    zIndex: 3,
  },
}));

function Services(props) {
  const { servicesData, width } = props;
  const [currentIndex, setCurrentIndex] = useState(0);
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <div className={classes.layer}>
        <img
          src={"/images/work.jpg"}
          className={classes.layer}
          alt="pen and ipad background image"
        />
      </div>
      <div className={classes.layer2}></div>
      <div className={classes.mainContent}>
        <Title
          className={classes.title}
          dark
          secondaryTitle="Tailored to Your Needs"
        >
          Our Services{" "}
        </Title>
        {width === "lg" || width === "xl" ? (
          <DesktopServices
            services={servicesData}
            currentIndex={currentIndex}
            setCurrentIndex={setCurrentIndex}
          />
        ) : (
          <ResponsiveServices menus={servicesData.menus} />
        )}
      </div>
    </div>
  );
}

Services.propTypes = {
  servicesData: PropTypes.object.isRequired,
  width: PropTypes.string.isRequired,
};

export default withWidth()(Services);
