import React, { useEffect } from "react";
import Layout from "features/layout/Layout";

//Component
import IndividualBlog from "features/blog/IndividualBlog";
export default function BlogDetailPage() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <Layout>
      <IndividualBlog />
    </Layout>
  );
}
