import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import PropTypes from 'prop-types';
import MenuDescriptions from './MenuDescriptions';
import MenuList from './MenuList';
const useStyles = makeStyles((theme) => ({
  root: {
    // color: '#fff',
    // background: '#F8F8F9',
    // background: theme.palette.background.paper,
    width: '100%',
    minHeight: '85vh',
    [theme.breakpoints.down('lg')]: {
      height: '100vh',
    },
    padding: theme.spacing(4),
    paddingTop: theme.spacing(3),
    // display: 'grid',
    // placeItems: 'center',
    zIndex: 5,
  },
}));

export default function DesktopServices(props) {
  const classes = useStyles();
  const { services, currentIndex, setCurrentIndex } = props;

  return (
    <div className={classes.root}>
      <Grid
        container
        spacing={0}
        justify="center"
        alignItems="center"
        direction="row"
      >
        <Grid item xl={4} lg={5}>
          <MenuList
            menus={services.menus}
            currentIndex={currentIndex}
            setCurrentIndex={setCurrentIndex}
          />
        </Grid>
        <Grid
          item
          lg={5}
          xl={5}
          style={{
            display: 'grid',
            placeItems: 'center',
          }}
        >
          <MenuDescriptions
            menus={services.menus}
            currentIndex={currentIndex}
          />
        </Grid>
      </Grid>
    </div>
  );
}

DesktopServices.propTypes = {
  services: PropTypes.object.isRequired,
  currentIndex: PropTypes.number.isRequired,
  setCurrentIndex: PropTypes.func.isRequired,
};
