import React from "react";
import PropTypes from "prop-types";

//Mui
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";

//Component
import RoundButton from "components/RoundButton";
import Hidden from "@material-ui/core/Hidden";
import withWidth from "@material-ui/core/withWidth";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    height: "100vh",
    // display: 'grid',
    // placeItems: 'start',
    position: "relative",
    overflow: "hidden",
  },
  img: {
    width: 400,
    height: 400,
    [theme.breakpoints.down("md")]: {
      width: 300,
      height: 300,
    },
    [theme.breakpoints.down("sm")]: {
      width: 350,
      height: 350,
    },
    [theme.breakpoints.down("xs")]: {
      width: 200,
      height: 200,
    },
  },
  textdiv: {
    width: "100%",
    [theme.breakpoints.down("sm")]: {
      padding: theme.spacing(5, 0),
      // //   width: '70%',
    },
  },
  text1: {
    color: "#000",
    fontFamily: "Ninuto",
    letterSpacing: "-0.8px",
    [theme.breakpoints.down("md")]: {
      fontSize: "45px",
    },
    [theme.breakpoints.down("sm")]: {
      fontSize: "45px",
    },
    [theme.breakpoints.down("xs")]: {
      fontSize: "25px",
    },
    fontSize: "46px",
    overflow: "hidden",
    whiteSpace: "nowrap",
    fontWeight: "700",
    margin: "0 auto",
    animation: "$typing1 7s steps(35, end) 0s infinite",
  },
  text2: {
    color: "#000",
    letterSpacing: "-0.8px",
    opacity: 0,
    fontFamily: "Ninuto",
    fontWeight: "700",
    fontSize: "46px",
    overflow: "hidden",
    // borderRight: '.08em solid #F5D91B',
    whiteSpace: "nowrap",
    margin: "0 auto",

    animation: "$typing2 7s steps(35, end) 3.5s infinite",

    marginTop: "-80px",
    [theme.breakpoints.down("md")]: {
      fontSize: "40px",
      marginTop: "-70px",
    },
    [theme.breakpoints.down("sm")]: {
      fontSize: "45px",
      marginTop: "-70px",
    },
    [theme.breakpoints.down("xs")]: {
      fontSize: "25px",
      marginTop: "-40px",
    },
  },

  "@keyframes typing1": {
    from: { width: 0 },
    "37%": { width: "100%" },
    "49%": { width: 0 },
    "100%": { width: 0 },
  },
  "@keyframes typing2": {
    from: { width: 0, opacity: 1 },
    "37%": {
      width: "100%",
      opacity: 1,
    },
    "49%": { width: 0, opacity: 1 },
    "100%": { width: 0, opacity: 1 },
  },
  "@keyframes blink-caret": {
    "from, to": {
      borderRight: ".08em solid #F5D91B",
      borderColor: "transparent",
    },
    "50%": {
      borderRight: ".08em solid #F5D91B",
      borderColor: "#F5D91B",
    },
  },
  scrollDowns: {
    position: "absolute",
    top: "90%",
    right: 0,
    bottom: 0,
    left: 0,

    display: "flex",
    justifyContent: "center",
    alignItems: "center",

    height: "55px",
    cursor: "pointer",
  },
  icon: {
    fontSize: 60,
    color: theme.palette.secondary.main,
    [theme.breakpoints.down("sm")]: {
      fontSize: 50,
    },
  },
  desc: {
    marginTop: theme.spacing(1),
    width: "100%",
    display: "grid",
    placeItems: "center",
    padding: "0 7%",

    [theme.breakpoints.down("sm")]: {
      marginTop: 0,
    },
  },
  moreButton: {
    marginTop: theme.spacing(2),
  },
}));

function Banner(props) {
  const classes = useStyles();
  const { width, data } = props;

  const handleClick = () => {
    if (window.scrollTo) {
      let dest = document.getElementById("someworks");

      window.scroll({ top: dest.offsetTop, behavior: "smooth" });
    }
  };

  console.log(width);

  return (
    <Grid container className={classes.root} alignItems={"center"}>
      <Hidden xsDown>
        <img
          src={"/images/background/back.jpg"}
          style={{
            width: "100%",
            height: "100vh",
            position: "absolute",
            objectFit: "cover",
            zIndex: 0,
          }}
        />
      </Hidden>

      <Hidden xsDown>
        <React.Fragment>
          <Hidden mdUp>
            <img
              src={"/images/background/smBack.png"}
              alt="banner background"
              style={{
                width: "100%",
                height: "100vh",
                position: "absolute",
                zIndex: 1,
                objectFit: "cover",
              }}
            />
          </Hidden>
          <Hidden mdDown>
            <img
              src={"/images/background/test.png"}
              alt="banner background"
              style={{
                width: "100%",
                objectFit: "cover",
                height: "100vh",
                position: "absolute",
                zIndex: 1,
              }}
            />
          </Hidden>
          <Hidden lgUp smDown>
            <img
              src={"/images/background/tab4.png"}
              alt="banner background"
              style={{
                width: "100%",
                height: "100vh",
                position: "absolute",
                zIndex: 1,
                objectFit: "cover",
              }}
            />
          </Hidden>
        </React.Fragment>
      </Hidden>

      <Grid
        item
        lg={7}
        style={{
          display: "grid",
          placeItems: "start",
          zIndex: 10,
        }}
      >
        <div>
          <div className={classes.textdiv}>
            <Typography className={classes.text1} align="center">
              We Build{" "}
              <span style={{ color: "#F5D91B", fontFamily: "Yeon Sung" }}>
                Brands.
              </span>
            </Typography>
            <Typography className={classes.text2} align="center">
              Unique is in our{" "}
              <span style={{ color: "#F5D91B", fontFamily: "Yeon Sung" }}>
                Nature.
              </span>
            </Typography>
          </div>
          <div className={classes.desc}>
            <Typography variant="subtitle1" align="center">
              We are full-fledged communications and public relations firm with
              offices in the Washington DC, Metropolitan Area and Addis Ababa,
              Ethiopia. At 251 Communications, we manage our international and
              local clients’ communications, marketing, research, logistics,
              media, PR, branding and production needs
            </Typography>
            <RoundButton className={classes.moreButton} onClick={handleClick}>
              See more
            </RoundButton>
          </div>
        </div>
      </Grid>
    </Grid>
  );
}
Banner.propTypes = {
  width: PropTypes.string,
  data: PropTypes.object,
};
export default withWidth()(Banner);
