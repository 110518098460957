const work = {
  companies: [
    {
      companyName: 'Buna Pen',
      alias: 'buna',
      worksDone: 'Branding, Logos and Video',
      img: '/images/buna.jpg',
    },

    {
      companyName: 'Malta Guiness',
      worksDone: '360  Campaign',
      alias: 'malta',
      img: '/images/malta.jpg',
    },
    {
      companyName: 'Yegna',
      worksDone: '360 Campaign',
      alias: 'yegna',
      img: '/images/yegna.jpg',
    },
    {
      companyName: 'Coca Cola',
      worksDone: 'PR Work',
      img: '/images/cocacola.png',
    },

    {
      companyName: 'Pizza Hut Ethiopia',
      worksDone: 'PR | Public Relations, Market Research, Launch',
      img: '/images/PizzaHutEthiopia.png',
    },
    {
      companyName: 'Etete Dairy',
      alias: 'etete',
      worksDone: 'Branding, Logos and Video',
      img: '/images/etete.jpg',
    },
  ],
};

export default work;
