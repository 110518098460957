import React from "react";
import WithAuth from "utils/WithAuth";
import Layout from "features/admin/layout/Layout";
import AdminHome from "features/admin/home/AdminHome";
const AdminHomePage = () => {
  return (
    <WithAuth>
      <Layout>
        <AdminHome />
      </Layout>
    </WithAuth>
  );
};

export default AdminHomePage;
