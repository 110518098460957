import React from 'react';
import PropTypes from 'prop-types';

//Material ui
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    minHeight: '40vh',
    // background: 'red',

    padding: theme.spacing(2, 0),
    [theme.breakpoints.up('lg')]: {
      minHeight: '60vh',
    },
  },
  grid: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',

    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(2, 0),
    },
  },
  slogan: {
    fontSize: '50px',
    fontWeight: 'bolder',
    // textAlign: 'center',
    [theme.breakpoints.down('md')]: {
      fontSize: '45px',
    },

    [theme.breakpoints.down('sm')]: {
      textAlign: 'center',
    },
    [theme.breakpoints.down('xs')]: {
      textAlign: 'center',
      fontSize: '30px',
    },
  },
  subtext: {
    width: '70%',
  },
  description: {
    // fontWeight: 'bolder',
    fontSize: '18px',
    width: '70%',
    [theme.breakpoints.down('sm')]: {
      fontSize: '20px',
    },
  },
}));

const ProjectDescription = (props) => {
  const classes = useStyles();
  const { projectData } = props;

  return (
    <Grid
      container
      className={classes.root}
      justify="center"
      alignItems="center"
    >
      <Grid item xs={12} sm={12} md={6} align="center">
        <Typography
          className={classes.slogan}
          style={{ color: projectData && `${projectData.textColor}` }}
        >
          {' '}
          {projectData ? projectData.title : ''}
        </Typography>
        <Typography
          variant="body1"
          color="textSecondary"
          className={classes.subtext}
        >
          {' '}
          {projectData ? projectData.work : ''}
        </Typography>
      </Grid>
      <Grid item xs={12} sm={12} md={6} className={classes.grid}>
        <Typography className={classes.description} align="center">
          {' '}
          {projectData ? projectData.description : ''}
        </Typography>
      </Grid>
    </Grid>
  );
};

ProjectDescription.propTypes = {
  projectData: PropTypes.object,
};

export default ProjectDescription;
