import React, { useEffect, useState } from "react";

import { useHistory } from "react-router-dom";

//Component
import Title from "components/Title";
import BlogCard from "components/BlogCard";
import BlogCardSkeleton from "components/BlogCardSkeleton";

//Data Query
import { database } from "config/fire-config";

//Material ui
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";

const useStyles = makeStyles((theme) => ({
  generalRoot: {
    position: "relative",
    zIndex: 2,
    background: "#fff",

    margin: "0",
  },
  root: {
    position: "relative",
    padding: theme.spacing(8, 0),

    [theme.breakpoints.down("sm")]: {
      padding: theme.spacing(4, 0),
    },
    [theme.breakpoints.down("xs")]: {
      padding: theme.spacing(3, 0),
    },
  },
  grid: {
    padding: theme.spacing(2, 2),
  },
}));
const Blog = () => {
  const classes = useStyles();
  const [blogItems, setBlogItems] = useState({});
  const [loading, setLoading] = useState(false);
  const history = useHistory();
  useEffect(() => {
    setLoading(true);
    const ref = database.ref().child("blogs").orderByChild("meta/lastUpdated");

    var onValueChange = ref.on("value", function (snap) {
      if (snap.val() === null || snap.val() === undefined) {
        setBlogItems({});
        setLoading(false);
      } else {
        setBlogItems(snap.val());
        setLoading(false);
      }
    });

    return () => ref.off("value", onValueChange);
  }, []);

  const handleClick = (id) => {
    history.push(`/blogs/${id}`);
  };

  return (
    <div className={classes.generalRoot}>
      {!loading ? (
        <div>
          <Title dark={true} secondaryTitle="Some of Our Thoughts">
            Blogs
          </Title>
          <Grid
            container
            className={classes.root}
            direction="row"
            justify="center"
            alignItems="center"
          >
            {blogItems !== {} ? (
              Object.keys(blogItems)
                .slice(0, 3)
                .map(
                  (key, index) =>
                    blogItems[`${key}`].meta.published === true && (
                      <Grid
                        item
                        key={index}
                        xs={12}
                        sm={6}
                        md={4}
                        lg={3}
                        className={classes.grid}
                      >
                        <BlogCard
                          blog={blogItems[`${key}`]}
                          onClick={() => {
                            handleClick(key);
                          }}
                        />
                      </Grid>
                    )
                )
            ) : (
              <Typography align="center">No blogs at the moment</Typography>
            )}
          </Grid>
        </div>
      ) : (
        <div>
          <Title dark={true} secondaryTitle="Some of Our Thoughts">
            Blogs
          </Title>
          <BlogCardSkeleton />
        </div>
      )}
    </div>
  );
};

export default Blog;
