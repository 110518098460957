import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
// import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';

const useStyles = makeStyles((theme) => ({
  root: {
    paddingTop: theme.spacing(10),
    fontSize: '55px',
    fontWeight: '700',
    [theme.breakpoints.down('lg')]: {
      fontSize: '55px',
    },
    [theme.breakpoints.down('sm')]: {
      fontSize: '55px',
      paddingTop: theme.spacing(7),
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: '45px',
    },
    flexGrow: 0,
    flexShrink: 0,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',

    textTransform: 'none',
    lineHeight: '10px',
    whiteSpace: 'nowrap',
    fontFamily: 'Alegreya Sans SC',
  },
  titleBox: {
    display: 'flex',
  },
}));

const Title = (props) => {
  const {
    secondaryTitle,
    className,
    dark,
    color,
    children,
    style,
    ...rest
  } = props;

  const classes = useStyles();

  const rootClassName = clsx(
    {
      [classes.root]: true,
    },
    className,
  );

  const finalStyle = { ...style };

  if (dark === true) {
    finalStyle.color = '#000';
  } else if (dark === false) {
    finalStyle.color = '#fff';
  } else {
    finalStyle.color = `${color}`;
  }

  return (
    <React.Fragment>
      <Typography
        {...rest}
        className={rootClassName}
        style={finalStyle}
        variant="overline"
      >
        {children}
      </Typography>
      <Typography
        style={{
          color: dark ? '#414040' : '#ecece7',
          fontSize: '22px',
          paddingTop: '40px',
          fontWeight: '600',
        }}
        align="center"
      >
        {secondaryTitle}
      </Typography>
    </React.Fragment>
  );
};

Title.propTypes = {
  children: PropTypes.node,
  secondaryTitle: PropTypes.string,
  className: PropTypes.string,
  dark: PropTypes.bool,
  style: PropTypes.object,
  color: PropTypes.string,
};

Title.defaultProps = {
  style: {},
  dark: false,
  color: '#000',
};

export default Title;
