import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";

//Component
import BlogHeader from "./BlogHeader";
import BlogCard from "components/BlogCard";
import BlogCardSkeleton from "components/BlogCardSkeleton";

//Data Query
import { database } from "config/fire-config";

//Material ui
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(8, 0),

    [theme.breakpoints.down("sm")]: {
      padding: theme.spacing(4, 0),
    },
    [theme.breakpoints.down("xs")]: {
      padding: theme.spacing(3, 0),
    },
  },
  grid: {
    padding: theme.spacing(2, 2),
  },
}));
const BlogList = () => {
  const classes = useStyles();
  const [blogItems, setBlogItems] = useState({});
  const [loading, setLoading] = useState(false);
  const history = useHistory();
  useEffect(() => {
    setLoading(true);
    const ref = database.ref().child("blogs").orderByChild("timeStamp");

    var onValueChange = ref.on("value", function (snap) {
      if (snap.val() === null || snap.val() === undefined) {
        setBlogItems({});
        setLoading(false);
      } else {
        setBlogItems(snap.val());
        setLoading(false);
      }
    });

    return () => ref.off("value", onValueChange);
  }, []);

  const handleClick = (id) => {
    history.push(`/blogs/${id}`);
  };

  return !loading ? (
    <div style={{ backgroundColor: "#fff" }}>
      <BlogHeader />

      <Grid
        container
        className={classes.root}
        direction="row"
        justify="center"
        alignItems="center"
      >
        {Object.keys(blogItems).map(
          (key, index) =>
            blogItems[`${key}`].meta.published === true && (
              <Grid
                item
                key={index}
                xs={12}
                sm={6}
                md={4}
                lg={3}
                className={classes.grid}
              >
                <BlogCard
                  blog={blogItems[`${key}`]}
                  onClick={() => {
                    handleClick(key);
                  }}
                />
              </Grid>
            )
        )}
      </Grid>
    </div>
  ) : (
    <div>
      <BlogHeader />
      <BlogCardSkeleton />
    </div>
  );
};

export default BlogList;
