import React from "react";
import Login from "features/auth/Login";
import Layout from "features/layout/Layout";

export default function AdminLogin() {
  return (
    <Layout>
      <Login />
    </Layout>
  );
}
