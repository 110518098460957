import React from 'react';
import PropTypes from 'prop-types';

//Component
import Title from 'components/Title';
import NormalImage from 'components/NormalImage';

//Material ui
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Container from '@material-ui/core/Container';
import Card from '@material-ui/core/Card';

const useStyles = makeStyles((theme) => ({
  root: {
    background: '#F3F3F4',
    width: '100%',
    minHeight: '85vh',
    position: 'relative',
    zIndex: 2,
    display: 'grid',
    placeItems: 'center',
    overflow: 'hidden',
    paddingBottom: theme.spacing(10),
  },
  grid: {
    padding: theme.spacing(10, 0),
  },
  title: {},

  img: {
    // opacity: '0.3',
    borderRadius: '10px',
    transition: 'transform 0.5s',
    filter: 'grayscale(100%)',
    '&:hover': {
      cursor: 'pointer',
      filter: 'grayscale(0%)',
      transform: 'scale(1.2) ',
      transition: 'transform 0.5s',
    },
    width: 150,
    height: 150,
    objectFit: 'contain',
    [theme.breakpoints.down('md')]: {
      filter: 'grayscale(0%)',
    },
    [theme.breakpoints.down('xs')]: {
      width: 100,
      height: 100,
    },
  },
}));

const Clients = (props) => {
  const classes = useStyles();
  const { clientsData } = props;

  return (
    <div className={classes.root}>
      <Title
        className={classes.title}
        dark
        secondaryTitle="We've Worked with Some of the Best"
      >
        Our Clients
      </Title>
      <Container maxWidth="lg">
        <Grid
          container
          justify="center"
          alignItems="center"
          spacing={3}
          style={{ marginTop: '24px' }}
        >
          {clientsData.map((client, index) => (
            <Grid
              item
              key={`client-${index}`}
              align="center"
              xs={6}
              sm={6}
              md={4}
              lg={3}
              className={classes.grid}
            >
              <Card elevation={0}>
                <a
                  href={`${client.link}`}
                  className={classes.anchor}
                  el="noopener"
                  rel="noreferrer"
                  target="_blank"
                >
                  <NormalImage
                    src={client.companyLogo}
                    alt={`${client.companyName}`}
                    className={classes.img}
                  />
                </a>
              </Card>
            </Grid>
          ))}
        </Grid>
      </Container>
    </div>
  );
};

Clients.propTypes = {
  clientsData: PropTypes.array,
};

export default Clients;
