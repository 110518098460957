import React, { useState } from "react";
import Paper from "@material-ui/core/Paper";
import Button from "@material-ui/core/Button";
import AddIcon from "@material-ui/icons/Add";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import BootstrapInput from "components/BootStrapInput";
import { database } from "config/fire-config";
import { useHistory } from "react-router-dom";
import slugify from "slugify";
import { makeStyles } from "@material-ui/core/styles";
import { v4 as uuidv4 } from "uuid";

const useStyles = makeStyles((theme) => ({
  root: {
    transform: "scale(0.93)",
    transition: "transform 0.5s",
    "&:hover": {
      transform: "scale(1)",
      transition: "transform 0.5s",
    },
    margin: theme.spacing(3),
    width: "25%",
    height: "30vh",
    display: "grid",
    placeItems: "center",
    borderRadius: "10px",
    cursor: "pointer",
    [theme.breakpoints.down("md")]: {
      width: "35%",
      height: "20vh",
    },
    [theme.breakpoints.down("xs")]: {
      margin: "auto",
      width: "90%",
      height: "20vh",
    },
  },
  btn: {
    color: theme.palette.secondary.main,
  },
  inputs: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  actionBtn: {
    margin: theme.spacing(1),
    "&:hover": {
      color: theme.palette.primary.main,
    },
  },
}));

export default function BlogStarter() {
  const classes = useStyles();
  const [openStartModal, setOpenStartModal] = useState(false);
  const [blogTitle, setBlogTitle] = useState("");
  const [writterName, setWritterName] = useState("");
  const [loading, setLoading] = useState(false);
  const history = useHistory();
  const handleCloseStartModal = () => {
    setOpenStartModal(false);
  };
  const handleProceedClick = () => {
    setLoading(true);
    const newBlogRef = database.ref(
      "blogs/" + `${slugify(blogTitle)}-${uuidv4()}`
    );
    newBlogRef
      .set({
        meta: {
          title: blogTitle,
          writterName: writterName,
          published: false,
          lastUpdated: new Date().toISOString(),
        },
      })
      .then((data) => {
        history.push("/admin/blogs/[blogId]", `/admin/blogs/${newBlogRef.key}`);
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
      });
  };
  return (
    <div>
      <Paper
        elevation={1}
        className={classes.root}
        onClick={() => setOpenStartModal(true)}
      >
        <Button
          startIcon={<AddIcon />}
          // className={classes.btn}
        >
          Start a new blog
        </Button>
      </Paper>
      <Dialog open={openStartModal} onClose={handleCloseStartModal}>
        <DialogTitle>New Blog </DialogTitle>
        <DialogContent>
          <DialogContentText>
            Fill the form and start your blog
          </DialogContentText>
          <BootstrapInput
            placeholder="Blog Title"
            value={blogTitle}
            onChange={(e) => setBlogTitle(e.target.value)}
            className={classes.inputs}
            fullWidth
          />
          <BootstrapInput
            placeholder="Writer Name"
            value={writterName}
            onChange={(e) => setWritterName(e.target.value)}
            className={classes.inputs}
            fullWidth
          />

          <DialogActions>
            <Button
              variant="contained"
              className={classes.actionBtn}
              onClick={handleCloseStartModal}
              variant="outlined"
            >
              Cancel
            </Button>
            <Button
              variant="contained"
              color="primary"
              className={classes.actionBtn}
              onClick={handleProceedClick}
              disabled={loading}
            >
              {" "}
              {loading ? "Creating" : "Next"}
            </Button>
          </DialogActions>
        </DialogContent>
      </Dialog>
    </div>
  );
}
