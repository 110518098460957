import React from 'react';

//Mui
import { makeStyles } from '@material-ui/core/styles';
const useStyles = makeStyles((theme) => ({
  root: {
    position: 'fixed',
    height: '100vh',
    width: '100%',
    zIndex: 2000,
    background: 'rgba(50, 54, 65, 0.9)',

    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'center',
    alignItems: 'center',
  },
  circle1: {
    background: theme.palette.primary.main,
    height: 15,
    width: 15,
    borderRadius: '50%',
    marginRight: theme.spacing(1),
    animation: `$myEffect 0.8s infinite`,
  },
  circle2: {
    background: theme.palette.primary.main,
    borderRadius: '50%',
    height: 15,
    width: 15,
    animation: `$myEffect 0.8s infinite`,
    animationDelay: '0.1s',
    marginRight: theme.spacing(1),
  },
  circle3: {
    background: theme.palette.primary.main,
    borderRadius: '50%',
    height: 15,
    width: 15,
    marginRight: theme.spacing(1),
    animation: `$myEffect 0.8s infinite`,
    animationDelay: '0.2s',
  },

  '@keyframes myEffect': {
    '0%': {
      height: 30,
      width: 30,
    },

    '100%': {
      height: 15,
      width: 15,
    },
  },
}));

const CustomLoading = () => {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <div className={classes.circle1} />
      <div className={classes.circle2} />
      <div className={classes.circle3} />
    </div>
  );
};

export default CustomLoading;
