import React, { useEffect, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import Button from '@material-ui/core/Button';
import Box from '@material-ui/core/Box';
import ImageIcon from '@material-ui/icons/Image';
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';

const thumb = {
  display: 'inline-flex',
  borderRadius: 2,
  border: '1px solid #eaeaea',
  marginBottom: 8,
  marginRight: 8,
  width: 100,
  height: 100,
  padding: 4,
  boxSizing: 'border-box',
};

const thumbInner = {
  display: 'flex',
  minWidth: 0,
  overflow: 'hidden',
};

const img = {
  display: 'block',
  width: 'auto',
  height: '100%',
};

const useStyles = makeStyles((theme) => ({
  dropZone: {
    height: '15vh',
    margin: 'auto',
    width: '50%',
    background: theme.palette.background.default,
    [theme.breakpoints.down('xs')]: {
      height: '30vh',
    },
  },
  previewImage: {
    objectFit: 'cover',
    position: 'absolute',
    height: '15vh',
    zIndex: 0,
    opacity: 0.7,
    [theme.breakpoints.down('xs')]: {
      height: '30vh',
    },
  },
}));

function ImageUploader(props) {
  const classes = useStyles();
  const { onImageSelect, image, prevImage } = props;
  const [files, setFiles] = useState([]);
  useEffect(() => {
    if (image === null) {
      setFiles([]);
    }
  }, [image]);
  const { getRootProps, getInputProps } = useDropzone({
    accept: 'image/*',
    onDrop: (acceptedFiles) => {
      setFiles(
        acceptedFiles.map((file) =>
          Object.assign(file, {
            preview: URL.createObjectURL(file),
          }),
        ),
      );
    },
  });

  const thumbs = files.map((file) => (
    <div style={thumb} key={file.name}>
      <div style={thumbInner}>
        <img src={file.preview} style={img} />
      </div>
    </div>
  ));

  useEffect(() => {
    if (files && files.length > 0) {
      onImageSelect(files[0], files[0].path);
    }
  }, [files]);

  useEffect(
    () => () => {
      // Make sure to revoke the data uris to avoid memory leaks
      files.forEach((file) => URL.revokeObjectURL(file.preview));
    },
    [files],
  );

  return (
    <div
      {...getRootProps({ className: 'dropzone' })}
      className={classes.dropZone}
    >
      <input {...getInputProps()} />
      <Box display="flex" justifyContent="center">
        {files && files.length > 0 ? (
          <img src={files[0].preview} className={classes.previewImage} />
        ) : (
          prevImage &&
          prevImage !== '' && (
            <img src={`${prevImage}`} className={classes.previewImage} />
          )
        )}
        <Button
          variant="contained"
          color="secondary"
          startIcon={<ImageIcon />}
          style={{ marginTop: '30px', color: 'white' }}
        >
          Upload Image
        </Button>
      </Box>
    </div>
    //   <aside style={thumbsContainer}>{thumbs}</aside>
    // </section>
  );
}

ImageUploader.propTypes = {
  onImageSelect: PropTypes.func,
  image: PropTypes.any,
  prevImage: PropTypes.string,
};
export default ImageUploader;
