import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import PropTypes from 'prop-types';
// import NormalImage from 'components/NormalImage';
import TeamMemberCard from 'components/team/TeamMemberCard';
const useStyles = makeStyles((theme) => ({
  root: {
    width: '80%',
    display: 'flex',
    margin: 'auto',
    [theme.breakpoints.down('xs')]: {
      width: '100%',
    },
  },
}));

export default function TeamGallery(props) {
  const classes = useStyles();
  const { teamMembers } = props;
  return (
    <div className={classes.root}>
      <Grid
        container
        spacing={3}
        direction="row"
        alignItems="center"
        justify="center"
      >
        {teamMembers.map((mem, index) => (
          <Grid item xs={6} md={4} lg={4} key={`pic-${index}`}>
            <TeamMemberCard
              firstPicture={mem.firstPicture}
              secondPicture={mem.secondPicture}
              fullName={mem.fullName}
              position={mem.position}
            />
          </Grid>
        ))}
      </Grid>
    </div>
  );
}

TeamGallery.propTypes = {
  teamMembers: PropTypes.array.isRequired,
};
