import React from 'react';
import TeamDesc from './TeamDesc';
import TeamGallery from './TeamGallery';
import PropTypes from 'prop-types';
export default function OurTeam(props) {
  const { aboutData } = props;

  return (
    <div>
      <TeamDesc description={aboutData.description} />
      <TeamGallery teamMembers={aboutData.teamMembers} />
    </div>
  );
}

OurTeam.propTypes = {
  aboutData: PropTypes.object.isRequired,
};
