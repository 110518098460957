import React from "react";

//Material Ui
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import IconButton from "@material-ui/core/IconButton";
import { makeStyles } from "@material-ui/core/styles";

//Icon
import InstagramIcon from "@material-ui/icons/Instagram";

import FacebookIcon from "@material-ui/icons/Facebook";
import MailOutlineIcon from "@material-ui/icons/MailOutline";
import TwitterIcon from "@material-ui/icons/Twitter";
import LinkedInIcon from "@material-ui/icons/LinkedIn";

const useStyles = makeStyles((theme) => ({
  grid: {
    padding: theme.spacing(3, 1),
    backgroundColor: theme.palette.secondary.dark,
  },
  grid1: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    [theme.breakpoints.down("sm")]: {
      margin: theme.spacing(2, 0),
    },
  },
  img: {
    width: 120,
    height: 120,
  },

  title: {
    fontSize: "27px",
    fontWeight: "normal",
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: 1.2,
    letterSpacing: "normal",

    color: theme.palette.text.secondary,
    marginBottom: theme.spacing(1),
  },
  subtext: {
    color: theme.palette.text.secondary,
    fontSize: "20px",
    margin: theme.spacing(1, 0),
  },
  icon: {
    color: theme.palette.primary.main,
    "&:hover": {
      transform: "scale(1.3) ",
      transition: "transform 0.5s",
    },
  },
  copyrightgrid: {
    marginTop: "4rem",
    [theme.breakpoints.down("sm")]: {
      marginTop: "3rem",
    },
  },
  copyright: {
    fontSize: "12px",
    color: theme.palette.text.secondary,
  },
  text: {
    cursor: "pointer",
    "&:hover": {
      color: theme.palette.primary.main,
    },
  },
}));

const LowerFooter = () => {
  const classes = useStyles();

  const icon1 = [
    {
      component: <InstagramIcon />,
      label: "Instagram Icon",
      link: "https://www.instagram.com/251comms",
    },
    {
      component: <FacebookIcon />,
      label: "Facebook Icon",
      link: "https://www.facebook.com/251Comms",
    },
    {
      component: <TwitterIcon />,
      label: "Twitter Icon",
      link: "https://twitter.com/251Comms?s=20",
    },
  ];

  const icon2 = [
    {
      component: <LinkedInIcon />,
      label: "Linkedin Icon",
      link:
        "https://www.linkedin.com/company/251-communications-&-marketing-plc/",
    },

    {
      component: <MailOutlineIcon />,
      label: "Gmail Icon",
      link: "mailto:Info@251communications.com",
    },
  ];

  return (
    <Grid container className={classes.grid}>
      <Grid item xs={12} md={4}>
        <Grid
          container
          direction="column"
          justify="center"
          className={classes.grid1}
        >
          <Grid item>
            <img
              src={"/images/logo/251logo.svg"}
              alt="251 logo"
              className={classes.img}
            />
          </Grid>
          <Grid item align="center">
            <Typography className={classes.subtext} style={{ width: "80%" }}>
              Find us where you are comfortable{" "}
            </Typography>
          </Grid>
          <Grid item xs={8} align="center">
            {icon1.map((icon) => (
              <IconButton
                key={Math.random()}
                aria-label={`${icon.label}`}
                className={classes.icon}
                onClick={() => window.open(`${icon.link}`, "_blank")}
              >
                {icon.component}
              </IconButton>
            ))}
          </Grid>
          <Grid item xs={6} align="center">
            {icon2.map((icon) => (
              <IconButton
                key={Math.random()}
                aria-label={`${icon.label}`}
                className={classes.icon}
                onClick={() => window.open(`${icon.link}`, "_blank")}
              >
                {icon.component}
              </IconButton>
            ))}
          </Grid>
        </Grid>
      </Grid>{" "}
      <Grid item xs={12} md={4} className={classes.grid1}>
        <Grid container direction="column" justify="center">
          <Grid item align="center">
            <Typography className={classes.title}>Contacts</Typography>
          </Grid>
          <Grid item align="center" className={classes.subtext}>
            <Typography
              className={classes.text}
              onClick={() =>
                window.open(`mailto:Info@251communications.com`, "_blank")
              }
            >
              Info@251communications.com
            </Typography>
          </Grid>
          <Grid item align="center" className={classes.subtext}>
            <Typography
              className={classes.text}
              onClick={() => window.open(`tel:+251116630182`, "_blank")}
            >
              +251 116 630 182
            </Typography>
          </Grid>
        </Grid>
      </Grid>{" "}
      <Grid item xs={12} md={4} className={classes.grid1}>
        <Grid container direction="column" justify="center">
          <Grid item>
            <Typography align="center" className={classes.title}>
              Address
            </Typography>
          </Grid>
          <Grid item align="center" className={classes.subtext}>
            <Typography>Aster Plaza 3rd floor, Meskel Flower Bole.</Typography>
          </Grid>
          <Grid item align="center" className={classes.subtext}>
            <Typography>Addis Ababa, Ethiopia</Typography>
          </Grid>
        </Grid>
      </Grid>
      <Grid item className={classes.copyrightgrid} xs={12} align="center">
        <Typography align="center" className={classes.copyright}>
          © 2020 251communications. All rights reserved.
        </Typography>
      </Grid>
    </Grid>
  );
};

export default LowerFooter;
