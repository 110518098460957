import React from 'react';
import PropTypes from 'prop-types';

//Mui
import { makeStyles } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';
import Typography from '@material-ui/core/Typography';

const useStyles = makeStyles((theme) => ({
  root: {
    position: 'fixed',
    top: '45%',
    width: '100%',
    zIndex: 10,

    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  text: {
    fontSize: '20px',
    padding: theme.spacing(0, 1),
  },
}));

const FullPageLoading = (props) => {
  const classes = useStyles();
  const { message } = props;

  return (
    <div className={classes.root}>
      <CircularProgress />
      <Typography className={classes.text}>{message}</Typography>
    </div>
  );
};

FullPageLoading.propTypes = {
  message: PropTypes.string,
};

export default FullPageLoading;
