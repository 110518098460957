const blog = {
  blogImg: '/images/blogs1.jpg',
  blogs: [
    {
      title: "The Gurus' guide to Marketing",
      date: '04/10/2025',
      description:
        'With access to a number of resources along with knowledge of government policy, public perception, and how to support marketing promotion strategies. ',
      img: '/images/test1.jpg',
      author: 'Blen',
    },
    {
      title: 'Malta Guiness',
      date: '04/10/2025',
      description:
        'With access to a number of resources along with knowledge of government policy, public perception, and how to support marketing promotion strategies. ',
      img: '/images/test2.jpg',
      author: 'Blen',
    },
    {
      title: 'Malta Guiness',
      date: '04/10/2025',
      description:
        'With access to a number of resources along with knowledge of government policy, public perception, and how to support marketing promotion strategies. ',
      img: '/images/test3.jpg',
      author: 'Blen',
    },
  ],
};

export default blog;
