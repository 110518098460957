import React, { useState, useEffect } from "react";
import { database } from "config/fire-config";
import Button from "@material-ui/core/Button";
import { makeStyles } from "@material-ui/core/styles";
import PublishIcon from "@material-ui/icons/Publish";
import Box from "@material-ui/core/Box";
import AddBlogItemContainer from "./AddBlogItemContainer";
import { useParams, useHistory } from "react-router-dom";
import KeyboardReturnIcon from "@material-ui/icons/KeyboardReturn";
import ArchiveIcon from "@material-ui/icons/Archive";

const useStyles = makeStyles((theme) => ({
  root: {},
  publishBox: {
    margin: theme.spacing(2),
    [theme.breakpoints.down("xs")]: {
      margin: theme.spacing(1),
    },
  },
}));
export default function BlogComposer() {
  const classes = useStyles();
  const history = useHistory();
  const { blogId } = useParams();
  const [loading, setLoading] = useState(false);
  const [published, setPublished] = useState(false);
  const [itemLoading, setItemLoading] = useState(false);

  useEffect(() => {
    setItemLoading(true);
    let blogItemsRef = database.ref("blogs/" + blogId);
    let blogRefData = blogItemsRef.on("value", (snapshot) => {
      setItemLoading(false);
      const newBlogMeta = snapshot.val().meta;
      setPublished(newBlogMeta.published);
    });
    return () => {
      blogItemsRef.off("value", blogRefData);
    };
  }, []);

  const handlePublish = () => {
    setLoading(true);
    new Date().toISOString();
    database
      .ref("blogs/" + blogId + "/meta/published")
      .set(!published)
      .then((data) => {
        database
          .ref("blogs/" + blogId + "/meta/lastUpdated")
          .set(new Date().toISOString())
          .then((data) => {
            setLoading(false);
            history.push("/admin");
          })
          .catch((err) => console.log("error updating time", err));
      })
      .catch((err) => console.log("error publishing", err));
  };
  // console.log('Published is ', published);
  return (
    <div>
      <Box
        display="flex"
        justifyContent="flex-start"
        className={classes.publishBox}
      >
        <div style={{ flexGrow: 1 }}>
          <Button
            startIcon={<KeyboardReturnIcon />}
            onClick={() => history.push("/admin")}
            color="secondary"
            variant="outlined"
          >
            Back
          </Button>
        </div>

        {!itemLoading && (
          <Button
            startIcon={published ? <ArchiveIcon /> : <PublishIcon />}
            color="secondary"
            variant="contained"
            onClick={handlePublish}
            disabled={loading}
          >
            <React.Fragment>
              {published ? "Take-Down" : "Publish"}
            </React.Fragment>
          </Button>
        )}
      </Box>

      <AddBlogItemContainer />
    </div>
  );
}
