const project = {
  companies: {
    MALTA: {
      title: 'Malta Guiness',
      work: '360  Campaign',
      description:
        'After Diageo acquired Meta Abo Brewery in 2010, it was eager to expand its portfolio and enter the soft drink category. With that in mind, Malta Guniess: a nonalcoholic malt beverage was to be introduced for the first time to Ethiopia.',
      img: '/images/malta.jpg',
      layerColor: 'rgb(255, 206, 0, 0.2)',
      textColor: '#f02427',
    },
    ETETE: {
      title: 'Etete Diary',
      work:
        'TV commercial | Milk & Yogurt, Radio Production, OOH /Out of home/ and car branding',
      description:
        'Etete is a dairy company owned by Capital 54. After the company expansion finish Etete Dairy was Re-launch all range of their products.',
      img: '/images/etete.jpg',
      layerColor: 'rgb(14, 32, 81, 0.5)',
      textColor: '#f1dfae',
    },
    BUNA: {
      title: 'Buna Pen',
      work: '360 Campaign',
      description:
        'Buna Pen is the first locally manufactured ballpoint pen that began to be manufactured in Mojo town in July 2019 at a factory equipped with state of the art equipment with a capacity to manufacture 67.5 Million pens annually.',
      img: '/images/buna.jpg',
      layerColor: 'rgba(0,0,0,0.3)',
      textColor: '#3b86f8',
    },
    YEGNA: {
      title: 'Yegna',
      work: '360 Campaign',
      description:
        "Girl Hub Ethiopia (GHE) is a strategic collaboration between DFID and the Nike Foundation that is building evidence, catalyzing partnerships and using innovative communications platforms to reposition girls in society by unleashing 'the girl effect'. In April 2013, Girl Hub Ethiopia launched the youth-focused 'Yegna' social communications platform in Amhara region and Addis Ababa. To this effect, the Girl Hub Ethiopia has hired a creative agency called 251 communications to develop brand identity of this girl effect project.",
      img: '/images/yegna.jpg',
      layerColor: 'rgb(191, 64, 127, 0.4)',
      textColor: '#18e7c8',
    },
  },
};

export default project;
