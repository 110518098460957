import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import clsx from 'clsx';
const useStyles = makeStyles((theme) => ({
  root: {
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
    fontWeight: '700',
    color: theme.palette.primary.main,
    backgroundColor: '#000',
    borderRadius: '20px',
    '&:hover': {
      color: theme.palette.primary.main,
      backgroundColor: '#000',
    },
  },
}));
export default function RoundButton(props) {
  const classes = useStyles();
  const { children, onClick, className, style } = props;

  return (
    <Button
      onClick={onClick}
      className={clsx(classes.root, className)}
      style={style}
      variant="outlined"
      color="primary"
    >
      {children}
    </Button>
  );
}

RoundButton.propTypes = {
  children: PropTypes.string.isRequired,
  onClick: PropTypes.func,
  className: PropTypes.any,
  style: PropTypes.any,
};
