import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import { makeStyles } from "@material-ui/core/styles";
import EditIcon from "@material-ui/icons/Edit";
import DoneIcon from "@material-ui/icons/Done";
import CancelIcon from "@material-ui/icons/Cancel";
import { database } from "config/fire-config";
import { useParams } from "react-router-dom";

import BootstrapInput from "components/BootStrapInput";

const useStyles = makeStyles((theme) => ({
  root: {},
  doneIcon: {
    cursor: "pointer",
    marginLeft: theme.spacing(3),
    marginTop: theme.spacing(2),
    color: theme.palette.success.main,
  },
  cancelIcon: {
    cursor: "pointer",
    marginLeft: theme.spacing(2),
    marginTop: theme.spacing(2),
    color: theme.palette.error.main,
  },
  editBtn: {
    cursor: "pointer",
    margin: theme.spacing(2),
    [theme.breakpoints.down("xs")]: {
      marginTop: theme.spacing(1),
    },
  },
  input: {
    margin: theme.spacing(1),
  },
}));
export default function BlogTitle(props) {
  const classes = useStyles();
  const { meta, setItemLoading } = props;
  const [enableEdit, setEnableEdit] = useState(false);
  const [blogTitle, setBlogTitle] = useState("");
  const [writterName, setWritterName] = useState("");
  const [timeToRead, setTimeToRead] = useState("");
  const { blogId } = useParams();

  const handleOnSaveTitleClick = () => {
    database
      .ref("blogs/" + blogId + "/meta")
      .set({
        title: blogTitle,
        writterName: writterName,
        timeToRead: timeToRead,
        lastUpdated: meta.lastUpdated,
        published: meta.published,
      })
      .then((data) => setItemLoading(false))
      .catch((err) => console.log("error saving title", err));
    setEnableEdit(false);
  };

  useEffect(() => {
    if (meta) {
      setBlogTitle(meta.title);
      setWritterName(meta.writterName);
      setTimeToRead(meta.timeToRead ? meta.timeToRead : "");
    }
  }, [meta]);

  return (
    <div className={classes.root}>
      <Box display="flex" justifyContent="center" style={{ margin: "16px" }}>
        {enableEdit ? (
          <React.Fragment>
            <Box>
              <BootstrapInput
                placeholder="Blog Title"
                className={classes.input}
                value={blogTitle ? blogTitle : ""}
                onChange={(e) => setBlogTitle(e.target.value)}
              />
              <BootstrapInput
                placeholder="Writter Name"
                className={classes.input}
                value={writterName ? writterName : ""}
                onChange={(e) => setWritterName(e.target.value)}
              />
              <BootstrapInput
                placeholder="Time to Read (min)"
                className={classes.input}
                type="number"
                value={timeToRead}
                onChange={(e) => setTimeToRead(e.target.value)}
              />
            </Box>

            <DoneIcon
              className={classes.doneIcon}
              onClick={handleOnSaveTitleClick}
            />

            <CancelIcon
              className={classes.cancelIcon}
              onClick={() => setEnableEdit(false)}
            />
          </React.Fragment>
        ) : (
          <React.Fragment>
            <Box>
              <Typography variant="h4" align="center">
                {blogTitle}
              </Typography>
              <Typography variant="subtitle1" align="center">
                By: {` ${writterName}`}
              </Typography>
              <Typography variant="subtitle1" align="center">
                {" "}
                {`${timeToRead}(min) Read`}
              </Typography>
            </Box>

            <EditIcon
              onClick={() => setEnableEdit(true)}
              className={classes.editBtn}
            />
          </React.Fragment>
        )}
      </Box>
    </div>
  );
}

BlogTitle.propTypes = {
  meta: PropTypes.any.isRequired,
  setItemLoading: PropTypes.func.isRequired,
};
