import { createMuiTheme, responsiveFontSizes } from "@material-ui/core/styles";
import { red } from "@material-ui/core/colors";

const defaultPalette = {
  primaryColorMain: "#F5D91B",
  secondaryColorMain: "#000",
  secondaryColorLight: "#919AA2",
  backgroundColorDefault: "#fbfbfb",
  backgroundColorPaper: "#fff",
  textPrimaryColor: "#4f4f4f",
  textSecondaryColor: "#838483",
  textDisabledColor: "#808080",
};

let theme = createMuiTheme({
  palette: {
    primary: {
      main: defaultPalette.primaryColorMain,
      dark: "#B68B52",
      light: "#F9C54D",
    },
    secondary: {
      main: defaultPalette.secondaryColorMain,
      light: defaultPalette.secondaryColorLight,
      dark: "#000",
    },
    error: {
      main: red.A400,
    },
    background: {
      default: defaultPalette.backgroundColorDefault,
      paper: defaultPalette.backgroundColorPaper,
    },
    text: {
      primary: defaultPalette.textPrimaryColor,
      secondary: defaultPalette.textSecondaryColor,
      disabled: defaultPalette.textDisabledColor,
    },
  },
  typography: {
    // fontFamily: 'PT sans, sans-serif',
    fontFamily: "Nunito, sans-serif",
  },
});

theme = responsiveFontSizes(theme);

export default theme;
