import React from 'react';
import BlogData from 'data/blog';

//Material ui
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';

const useStyles = makeStyles((theme) => ({
  root: {
    position: 'relative',
  },
  img: {
    zIndex: -2,
    filter: 'grayscale(80%) contrast(100%)',
    height: '50vh',
    width: '100%',
    backgroundPosition: 'center center',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    [theme.breakpoints.down('md')]: {
      height: '25vh',
    },
    [theme.breakpoints.down('xs')]: {
      height: '30vh',
    },
  },
  box: {
    position: 'absolute',
    zIndex: 3,
    left: '50%',
    top: '50%',
    transform: 'translate(-50%, -65%)',
    [theme.breakpoints.down('xs')]: {
      transform: 'translate(-50%, -50%)',
    },
  },
  title: {
    fontSize: '50px',
    fontFamily: 'Unna',
    color: '#fff',
    [theme.breakpoints.down('md')]: {
      fontSize: '45px',
    },
    [theme.breakpoints.down('sm')]: {
      fontSize: '38px',
    },

    [theme.breakpoints.down('xs')]: {
      fontSize: '30px',
    },
  },
}));
const BlogHeader = () => {
  const classes = useStyles();
  //   const { projectData } = props;
  return (
    <div className={classes.root}>
      <div
        className={classes.img}
        style={{
          backgroundImage: `url(${BlogData['blogImg']})  `,
        }}
      />

      <Box className={classes.box}>
        {' '}
        <Typography className={classes.title} align="center">
          Blogs
        </Typography>
        <Typography
          variant="h6"
          align="center"
          style={{ color: '#fff', fontFamily: 'Unna' }}
        >
          Read new perspectives on various topics.
        </Typography>
      </Box>
    </div>
  );
};

export default BlogHeader;
