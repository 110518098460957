import React, { useState, useEffect } from 'react';
import Skeleton from '@material-ui/lab/Skeleton';
import PropTypes from 'prop-types';

export default function NormalImage(props) {
  const { src, className, style, alt } = props;

  const [imageLoaded, setImageLoaded] = useState(false);
  const [imageError, setImageError] = useState(false);
  // console.log('imageLoaded', imageLoaded, 'from src', src);
  useEffect(() => {
    const image = new Image();
    image.onload = handleImageLoad;
    image.onerror = handleImageError;
    image.src = src;
    return () => {
      setImageLoaded(false);
      setImageError(false);
      image.onload = null;
      image.onerror = null;
      image.src = null;
    };
  }, [src]);
  const handleImageLoad = () => {
    setImageLoaded(true);
    setImageError(false);
  };
  const handleImageError = (err) => {
    setImageError(true);
  };

  return (
    <React.Fragment>
      {imageError || !imageLoaded ? (
        <Skeleton
          variant="rect"
          // className={className ? className : {}}
          style={style ? style : {}}
        />
      ) : (
        <img
          alt={alt}
          onLoad={handleImageLoad}
          onError={handleImageError}
          src={src}
          className={className ? className : {}}
          style={style ? style : {}}
        />
      )}

      {/* 
      {imageLoaded ? <React.Fragment></React.Fragment> : <Loading />} */}
    </React.Fragment>
  );
}

NormalImage.propTypes = {
  src: PropTypes.string.isRequired,
  alt: PropTypes.string,
  disableSpinner: PropTypes.bool,
  className: PropTypes.any,
  style: PropTypes.any,
};
