import React, { useState } from "react";
import {
  Grid,
  Typography,
  Box,
  FormControl,
  FormHelperText,
} from "@material-ui/core";

import NormalImage from "components/NormalImage";
import { useHistory } from "react-router-dom";
import BootstrapInput from "components/BootStrapInput";
import CustomButton from "components/CustomButton";
import { auth } from "config/fire-config";
import { makeStyles } from "@material-ui/core/styles";
const useStyles = makeStyles((theme) => ({
  root: {
    background: "#E6E7E8",
    display: "grid",
    placeItems: "center",
    height: "100vh",
  },
  loginCard: {
    minHeight: "60%",

    width: "70%",
    [theme.breakpoints.down("lg")]: {
      width: "80%",
    },
    [theme.breakpoints.down("md")]: {
      width: "90%",
    },
    [theme.breakpoints.down("xs")]: {
      width: "90%",
    },
  },
  logoImg: {
    width: "50%",
    [theme.breakpoints.down("md")]: {
      width: "25%",
    },
  },
  logoContainer: {
    background: "#000",
    display: "grid",
    placeItems: "center",
    padding: theme.spacing(3),
  },
  loginContainer: {
    padding: "16px",
    paddingBottom: "50px",
    background: "#fff",
  },
  loginTitle: {
    paddingBottom: "50px",
    paddingTop: "50px",
    [theme.breakpoints.down("md")]: {
      paddingBottom: "40px",
      paddingTop: "40px",
    },
    [theme.breakpoints.down("sm")]: {
      paddingBottom: "35px",
      paddingTop: "35px",
    },
    [theme.breakpoints.down("xs")]: {
      paddingBottom: "25px",
      paddingTop: "25px",
    },
  },
  input: {
    margin: theme.spacing(1),
    width: "80%",
    [theme.breakpoints.down("xs")]: {
      width: "95%",
    },
  },
}));

export default function Login() {
  const classes = useStyles();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [emailError, setEmailError] = useState("");
  const history = useHistory();

  const handleLogin = () => {
    console.log("sending", email, "and", password);
    auth
      .signInWithEmailAndPassword(email, password)
      .then((user) => {
        history.push("/admin");
      })
      .catch((error) => {
        setEmailError("Invalid Email or password");
      });
  };
  return (
    <div className={classes.root}>
      <Grid
        container
        spacing={0}
        justify="center"
        alignItems="stretch"
        className={classes.loginCard}
      >
        <Grid
          item
          xs={12}
          sm={12}
          md={12}
          lg={3}
          className={classes.logoContainer}
        >
          <NormalImage
            src={"/images/logo/251logo.svg"}
            alt="251 logo"
            className={classes.logoImg}
          />
        </Grid>
        <Grid
          item
          xs={12}
          sm={12}
          md={12}
          lg={6}
          className={classes.loginContainer}
        >
          <Typography
            variant="h3"
            align="center"
            className={classes.loginTitle}
          >
            {" "}
            Login | Admin
          </Typography>

          <Box display="flex" justifyContent="center">
            <FormControl
              error={emailError !== ""}
              fullWidth
              className={classes.input}
            >
              <BootstrapInput
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                placeholder="username or email"
                error={emailError !== ""}
              />
              <FormHelperText>{emailError}</FormHelperText>
            </FormControl>
          </Box>
          <Box display="flex" justifyContent="center">
            <BootstrapInput
              fullWidth
              placeholder="password"
              className={classes.input}
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              type="password"
              onKeyPress={(e) => {
                if (e.key === "Enter") {
                  handleLogin();
                }
              }}
            />
          </Box>
          <Box
            display="flex"
            justifyContent="center"
            style={{ marginTop: "20px" }}
          >
            <CustomButton
              variant="contained"
              style={{ background: "#000" }}
              color="#000"
              onClick={handleLogin}
            >
              Login
            </CustomButton>
          </Box>
        </Grid>
      </Grid>
    </div>
  );
}
