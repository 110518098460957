import React, { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import dayjs from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime";

//Component
import Title from "components/Title";
import IndividualBlogSkeleton from "components/IndividualBlogSkeleton";

//Feature
import IndividualBlogImage from "./IndividualBlogImage";
import IndividualBlogParagraph from "./IndividualBlogParagraph";
import SimilarBlogs from "./SimilarBlogs";

//Data Query
import { database } from "config/fire-config";

//Material ui
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Container from "@material-ui/core/Container";
import Hidden from "@material-ui/core/Hidden";

const useStyles = makeStyles((theme) => ({
  root: {
    margin: theme.spacing(6, 0),
    padding: theme.spacing(0, 1),
    // display: 'grid',
    // placeItems: 'center',
  },
  grid: {
    // width: '75%',
    marginTop: theme.spacing(8),
    // padding: theme.spacing(1),
  },
  title: {
    fontSize: "35px",
    fontWeight: "bolder",
  },
  gridcontainer: {
    background: "#fbfbfb",
    paddingBottom: theme.spacing(5),
  },
  text: {
    color: "#000",
    fontWeight: 550,
  },
}));

const IndividualBlog = () => {
  const [singleBlog, setSingleBlog] = useState(null);
  const [blog, setBlog] = useState([]);
  const [loading, setLoading] = useState(false);
  const history = useHistory();
  const classes = useStyles();
  dayjs.extend(relativeTime);

  const { blogid } = useParams();
  useEffect(() => {
    setLoading(true);

    const ref = database.ref().child("blogs").orderByChild("timeStamp");
    let timer;
    var onValueChange = ref.on("value", function (snap) {
      if (snap.val() === null || snap.val() === undefined) {
        setSingleBlog({});
        setLoading(false);
      } else {
        setSingleBlog(snap.val()[`${blogid}`]);
        setBlog(snap.val());
        timer = setTimeout(() => {
          setLoading(false);
        }, 3000);
      }
    });

    return () => {
      clearTimeout(timer);
      ref.off("value", onValueChange);
    };
  }, [blogid]);

  const handleClick = (id) => {
    history.push(`/blogs/${id}`);
    window.scrollTo(0, 0);
  };

  return singleBlog && !loading && blog ? (
    <div>
      <Hidden mdDown>
        <div style={{ position: "fixed", top: "50%", left: "3%", zIndex: -1 }}>
          <Typography>WRITTEN BY</Typography>
          <Typography
            className={classes.text}
            variant="h6"
          >{`${singleBlog.meta.writterName} `}</Typography>
          <Typography
            style={{ fontSize: "12px" }}
            color="textSecondary"
          >{`${dayjs(singleBlog.meta.lastUpdated).fromNow("dd")} ago - ${
            singleBlog.meta.timeToRead ? singleBlog.meta.timeToRead : "a few"
          } min read`}</Typography>
        </div>
      </Hidden>

      <Container maxWidth="md">
        <Grid container className={classes.root}>
          <Grid className={classes.grid}>
            {/* {loading && <FullPageLoading />} */}
            <Typography className={classes.title}>
              {singleBlog.meta.title}
            </Typography>{" "}
            <Typography
              display="inline"
              variant="h6"
            >{`${singleBlog.meta.writterName} `}</Typography>
            <Typography
              style={{ fontSize: "12px" }}
              color="textSecondary"
            >{`${dayjs(singleBlog.meta.lastUpdated).fromNow("dd")} ago - ${
              singleBlog.meta.timeToRead ? singleBlog.meta.timeToRead : "a few"
            } min read`}</Typography>
            {singleBlog["blogItems"].map((item, index) =>
              item.type === "image" ? (
                <div key={index}>
                  <IndividualBlogImage
                    img={item.value}
                    caption={item.caption}
                  />
                </div>
              ) : item.type === "paragraph" ? (
                <div key={index}>
                  <IndividualBlogParagraph paragraph={item.value} />
                </div>
              ) : (
                <Typography
                  variant="h5"
                  key={index}
                  className={classes.text}
                >{`${item.value} `}</Typography>
              )
            )}
          </Grid>
        </Grid>
      </Container>
      <div className={classes.gridcontainer}>
        <Title dark={true} style={{ fontSize: "32px" }}>
          More Blogs
        </Title>
        <Container maxWidth="lg">
          <Grid container spacing={3} style={{ marginTop: "20px" }}>
            {Object.keys(blog)
              .slice(0, 3)
              .map(
                (key, index) =>
                  key !== blogid &&
                  blog[`${key}`].meta.published === true && (
                    <Grid item key={index} xs={12} sm={6} md={4} lg={3}>
                      <SimilarBlogs
                        blog={blog[`${key}`]}
                        onClick={() => {
                          handleClick(key);
                        }}
                      />
                    </Grid>
                  )
              )}
          </Grid>
        </Container>
      </div>
    </div>
  ) : (
    <IndividualBlogSkeleton />
  );
};

export default IndividualBlog;
