import React, { useEffect, useState } from "react";
import dayjs from "dayjs";
import PropTypes from "prop-types";
import relativeTime from "dayjs/plugin/relativeTime";

//Data Query
import { storage } from "config/fire-config";

//Component
import NormalImage from "./NormalImage";

//Material ui
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Typography from "@material-ui/core/Typography";
import Link from "@material-ui/core/Link";
import { makeStyles } from "@material-ui/core/styles";
const useStyles = makeStyles((theme) => ({
  card: {
    paddingBottom: theme.spacing(1),
    height: 420,

    [theme.breakpoints.down("xs")]: {
      height: "100%",
    },
  },
  img: {
    objectFit: "cover",
    width: "100%",
    height: 210,
    [theme.breakpoints.down("xs")]: {
      height: 150,
    },
  },
  title: {
    fontSize: "20px",
    fontWeight: "bolder",
    lineHeight: "115%",
    "&:hover": {
      textDecoration: "underline",
    },
  },
  subtitle: {
    fontWeight: 450,
    fontSize: "12px",
  },
  description: {
    paddingTop: theme.spacing(2),
    lineHeight: "115%",
    fontWeight: 500,
    display: "-webkit-box",
    height: "4.5em",
    "-webkit-line-clamp": 3,
    "-webkit-box-orient": "vertical",
    overflow: "hidden",
    textOverflow: "ellipsis",
  },

  link: {
    cursor: "pointer",
    textDecoration: "none!important",
  },
}));

const BlogCard = (props) => {
  const classes = useStyles();
  const { blog, onClick } = props;
  dayjs.extend(relativeTime);
  const [img, setImg] = useState(null);
  const [caption, setCaption] = useState("");
  const [description, setDescription] = useState("");

  function getHeadLine() {
    let headline = {};
    if (blog && blog["blogItems"]) {
      headline = blog["blogItems"].find((element) => {
        return element.type === "paragraph";
      });

      if (headline && headline.value) {
        setDescription(headline.value);
      }
    }
  }

  const getImg = () => {
    let pic = null;
    if (blog && blog["blogItems"] !== undefined) {
      pic = blog.blogItems.find((element) => {
        return element.type === "image";
      });
      if (pic && pic.value !== "") {
        pic.caption ? setCaption(pic.caption) : setCaption("Blog Image");
        storage
          .ref(pic.value)
          .getDownloadURL()
          .then(function (downloadURL) {
            setImg(downloadURL);
          });
      }
    }
  };

  useEffect(() => {
    getHeadLine();
    getImg();
  }, [blog]);

  return (
    <Link onClick={onClick} className={classes.link}>
      <Card className={classes.card} elevation={3}>
        <NormalImage
          src={`${img}`}
          alt={`${caption}`}
          className={classes.img}
        />
        <CardContent>
          <Typography className={classes.title}>{blog.meta.title}</Typography>
          <Typography
            className={classes.subtitle}
            style={{ marginTop: "10px" }}
          >{`Posted ${dayjs(blog.meta.lastUpdated).fromNow("dd")} ago - ${
            blog.meta.timeToRead ? blog.meta.timeToRead : "a few"
          } min read`}</Typography>
          <Typography
            className={classes.subtitle}
          >{`By ${blog.meta.writterName}`}</Typography>
          <Typography variant="body1" className={classes.description}>
            {description}
          </Typography>
        </CardContent>
      </Card>
    </Link>
  );
};

BlogCard.propTypes = {
  blog: PropTypes.object,
  onClick: PropTypes.func,
};

export default BlogCard;
