import React from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import MultiCarousel from "components/MultiCarousel";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import Title from "components/Title";
import { Link, useHistory } from "react-router-dom";
const useStyles = makeStyles((theme) => ({
  root: {
    position: "relative",
  },
  singleItem: {
    height: "110vh",
    [theme.breakpoints.down("md")]: {
      height: "100vh",
    },
    [theme.breakpoints.down("sm")]: {
      paddingTop: "120px",
    },
    [theme.breakpoints.down("xs")]: {
      paddingTop: "0px",
    },

    width: "100vw",
    display: "grid",
    placeItems: "center",
    position: "relative",
  },
  singlItemImg: {
    position: "absolute",
    height: "110vh",
    [theme.breakpoints.down("md")]: {
      height: "100vh",
    },
    width: "100vw",
    filter: "contrast(50%)",
    zIndex: -2,
    objectFit: "cover",
  },
  signleItemLayer: {
    position: "absolute",
    height: "110vh",
    [theme.breakpoints.down("md")]: {
      height: "100vh",
    },
    width: "100vw",
    zIndex: -1,
  },
  subtitle: {
    color: "rgba(255,255,255,0.5)",
    fontFamily: "Alegreya Sans SC",
    fontWeight: "500",

    fontSize: "50px",
    cursor: "pointer",
    [theme.breakpoints.down("lg")]: {
      fontSize: "40px",
    },
    [theme.breakpoints.down("sm")]: {
      fontSize: "25px",
    },
  },
}));

export default function ResponsiveSomeWorks(props) {
  const classes = useStyles();

  const history = useHistory();
  const { companies } = props;

  const items = companies.map((company, index) => (
    <div key={`comp-${index}`} className={classes.singleItem}>
      <img
        src={`${company.img}`}
        alt={`${company.alias}`}
        className={classes.singlItemImg}
      />

      <div
        className={classes.signleItemLayer}
        style={{
          background: `${company.layerColor}`,
        }}
      ></div>
      <div style={{ position: "relative", zIndex: 10 }}>
        <Typography
          className={classes.subtitle}
          style={{ color: company.textColor }}
        >
          {company.companyName}
        </Typography>
        <Typography variant="h6" style={{ color: "#fff", marginTop: "16px" }}>
          {company.worksDone}
        </Typography>

        <Button
          variant="contained"
          component={Link}
          to={`/project/${company.alias}`}
          style={{
            marginTop: "24px",
            background: company.textColor,
            color: "#fff",
            borderRadius: "15px",
          }}
        >
          View Work
        </Button>
      </div>
    </div>
  ));
  const handlePortfolioClick = () => {
    history.push("/portfolio");
  };
  return (
    <div id="someworks" className={classes.root}>
      <div
        style={{
          width: "100%",
          position: "absolute",
          top: "20px",
          display: "grid",
          placeItems: "center",
        }}
      >
        <Title secondaryTitle="Passion is in Our Recipe">Our Works</Title>
      </div>

      <MultiCarousel
        items={items}
        lower
        iconBase={23}
        allItems={companies}
        onEndClick={handlePortfolioClick}
      />
    </div>
  );
}

ResponsiveSomeWorks.propTypes = {
  companies: PropTypes.array,
};
