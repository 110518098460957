import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import clsx from 'clsx';

const useStyles = makeStyles((theme) => ({
  root: {},

  link: {
    borderRadius: '20px',
    borderTopRightRadius: '70px',
    borderBottomRightRadius: '70px',

    paddingLeft: theme.spacing(2),
    color: '#000',
    lineHeight: '70px',

    transition: 'font-size 0.4s',
    marginLeft: 0,
    '&:hover': {
      marginLeft: theme.spacing(1),
      fontSize: 50,
      fontWeight: '800',
      transition: 'font-size 0.4s, background-color 1s, margin-left 0.7s',
      color: theme.palette.primary.main,
      textShadow: '0.5px 0.5px gray',
    },
  },

  hovered: {
    marginLeft: theme.spacing(1),
    fontSize: 50,
    fontWeight: '800',
    transition: 'font-size 0.4s, margin-left 0.7s',
    textShadow: '0.5px 0.5px gray',
    color: theme.palette.primary.main,
  },
}));
export default function MenuList(props) {
  const { menus, currentIndex, setCurrentIndex } = props;
  const classes = useStyles();
  return (
    <div>
      {menus.map((men, index) => (
        <div
          key={`men-${index}`}
          onMouseEnter={() => setCurrentIndex(index)}
          onClick={() => setCurrentIndex(index)}
        >
          <Typography
            variant="h4"
            className={
              currentIndex === index
                ? clsx(classes.link, classes.hovered)
                : classes.link
            }
          >
            {men.menuItem}
          </Typography>
        </div>
      ))}
    </div>
  );
}

MenuList.propTypes = {
  menus: PropTypes.array.isRequired,
  currentIndex: PropTypes.number.isRequired,
  setCurrentIndex: PropTypes.func.isRequired,
};
