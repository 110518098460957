import React, { useState } from 'react';
import Button from '@material-ui/core/Button';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import Typography from '@material-ui/core/Typography';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import { makeStyles } from '@material-ui/core/styles';
import AddIcon from '@material-ui/icons/Add';
import ImageIcon from '@material-ui/icons/Image';
import ViewHeadlineIcon from '@material-ui/icons/ViewHeadline';
import TextFormatIcon from '@material-ui/icons/TextFormat';
import PropTypes from 'prop-types';
import { v4 as uuidv4 } from 'uuid';

const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: theme.spacing(6),
    display: 'grid',
    placeItems: 'end',
    padding: theme.spacing(4),
  },
}));
export default function AddBlogItem(props) {
  const classes = useStyles();
  const { setNewBlogItem } = props;
  const [addAnchor, setAddAnchor] = useState(null);

  const handleClickListItem = (event) => {
    setAddAnchor(event.currentTarget);
  };
  const handleClose = () => {
    setAddAnchor(null);
  };

  return (
    <div className={classes.root} id="addControl">
      <Button
        startIcon={<AddIcon />}
        variant="contained"
        color="secondary"
        onClick={handleClickListItem}
        aria-haspopup="true"
        aria-controls="add-item-menu"
        aria-label="when device is locked"
      >
        Add blog Item
      </Button>
      <Menu
        id="add-item-menu"
        anchorEl={addAnchor}
        keepMounted
        open={Boolean(addAnchor)}
        onClose={handleClose}
      >
        <MenuItem
          onClick={(event) => {
            setNewBlogItem({
              id: uuidv4(),
              type: 'subHeading',
              value: '',
            });
            handleClose();
          }}
        >
          <ListItemIcon>
            <TextFormatIcon />
          </ListItemIcon>
          <ListItemText>Sub-Heading</ListItemText>
        </MenuItem>

        <MenuItem
          onClick={(event) => {
            setNewBlogItem({
              id: uuidv4(),
              type: 'paragraph',
              value: '',
            });
            handleClose();
          }}
        >
          <ListItemIcon>
            <ViewHeadlineIcon />
          </ListItemIcon>
          <ListItemText>Paragraph</ListItemText>
        </MenuItem>

        <MenuItem
          onClick={(event) => {
            setNewBlogItem({
              id: uuidv4(),
              type: 'image',
              value: '',
            });

            let elmnt = document.getElementById('addControl');
            elmnt.scrollIntoView();
            handleClose();
          }}
        >
          <ListItemIcon>
            <ImageIcon />
          </ListItemIcon>
          <ListItemText>Image</ListItemText>
        </MenuItem>
      </Menu>
    </div>
  );
}

AddBlogItem.propTypes = {
  setNewBlogItem: PropTypes.func.isRequired,
};
