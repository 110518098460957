import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

//Data Query
import { storage } from 'config/fire-config';

//Component
import NormalImage from 'components/NormalImage';

//Material ui
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';

const useStyles = makeStyles((theme) => ({
  root: {
    margin: theme.spacing(5, 0),
    columnCount: 1,
  },
  img: {
    objectFit: 'cover',
    width: '100%',
    // height: 400,
    [theme.breakpoints.down('xs')]: {
      height: 150,
    },
  },
  text: {
    padding: theme.spacing(0, 2),
  },
}));

const IndividualBlogImage = (props) => {
  const { img, caption } = props;
  const classes = useStyles();
  const [image, setImage] = useState('');

  useEffect(() => {
    if (img !== '') {
      storage
        .ref(img)
        .getDownloadURL()
        .then(function (downloadURL) {
          setImage(downloadURL);
        });
    }
  }, [img]);
  return (
    <div className={classes.root}>
      <NormalImage
        src={`${image}`}
        alt={`${caption}`}
        className={classes.img}
      />
      <Typography align="center" color="textSecondary" className={classes.text}>
        {caption}
      </Typography>
    </div>
  );
};

IndividualBlogImage.propTypes = {
  img: PropTypes.string,
  caption: PropTypes.string,
};

export default IndividualBlogImage;
