import React, { useState, useEffect, useRef } from 'react';
import withWidth from '@material-ui/core/withWidth';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import clsx from 'clsx';
import ChevronRightIcon from '@material-ui/icons/ArrowForwardIos';
import ChevronLeftIcon from '@material-ui/icons/ArrowBackIos';

import PropTypes from 'prop-types';
import { current } from '@reduxjs/toolkit';
import IconButton from '@material-ui/core/IconButton';

const useStyles = makeStyles((theme) => ({
  root: {
    position: 'relative',

    // background: 'black',
  },
  carousel: {
    display: 'flex',
    overflowX: 'scroll',
    msOverflowStyle: 'none',
    scrollSnapType: 'x mandatory',
    scrollBehavior: 'smooth',
    position: 'relative',
    width: '100%',
    // paddingRight: '100px',
    // background: 'green',
    '&::-webkit-scrollbar': {
      display: 'none',
    },
  },

  carouselItem: {
    // width: '100%',
    flexShrink: 0,
    scrollSnapAlign: 'start',
    margin: 0,
    // background: 'yellow',
  },
  hiddenRightPush: {
    width: theme.spacing(5),
    visibility: 'hidden',
  },
  leftScrollBtn: {
    opacity: 0.9,
    position: 'absolute',
    zIndex: 1000,
    top: '45%',
    left: '15px',

    cursor: 'pointer',
    [theme.breakpoints.down('xs')]: {
      padding: theme.spacing(1),
      left: 0,
    },
  },
  rightScrollBtn: {
    opacity: 0.9,
    position: 'absolute',
    zIndex: 1000,
    top: '45%',
    right: '15px',

    cursor: 'pointer',
    [theme.breakpoints.down('xs')]: {
      padding: theme.spacing(1),
      right: 0,
    },
  },

  disabledScrollBtn: {
    opacity: '.3',
  },
  gradientLayer: {
    position: 'absolute',
    background: 'green',
    top: 0,
    bottom: 0,
    width: '100%',
    zIndex: 500,
  },
}));

function MultiCarousel(props) {
  const classes = useStyles();
  const {
    items,
    width,
    iconBase,
    single,
    identifier,
    allItems,
    onEndClick,
  } = props;
  const [currentPos, setCurrentPos] = useState(0);
  const [scrollPosX, setScrollPosX] = useState(0);
  const [disableBack, setDisableBack] = useState(false);
  const [disableForward, setDisableForward] = useState(false);
  const [iconSize, setIconSize] = useState(iconBase ? iconBase : 10);
  const [multiCarouselElement, setMultiCarouselElement] = useState(null);
  const [id] = useState(identifier ? identifier : 'test');

  useEffect(() => {
    if (allItems && allItems.length - 1 === currentPos) {
      setDisableForward(true);
    } else {
      setDisableForward(false);
    }
    if (currentPos === 0) {
      setDisableBack(true);
    } else {
      setDisableBack(false);
    }
  }, [currentPos]);
  useEffect(() => {
    if (width === 'xs') {
      setIconSize(iconBase ? iconBase : 10);
    } else if (width === 'md' || width === 'sm') {
      setIconSize(iconBase ? iconBase + 8 : 18);
    } else {
      setIconSize(iconBase ? iconBase + 12 : 22);
    }
  }, []);

  useEffect(() => {
    if (id) {
      const targetElement = document.getElementById(id);
      setMultiCarouselElement(targetElement);
      targetElement.addEventListener('scroll', () =>
        setScrollPosX(targetElement.scrollLeft),
      );
    }
  }, [id]);

  useEffect(() => {
    if (multiCarouselElement) {
      if (multiCarouselElement.scrollLeft === 0) {
        setDisableBack(true);
      } else {
        setDisableBack(false);
      }
      if (
        multiCarouselElement.offsetWidth + multiCarouselElement.scrollLeft >=
        multiCarouselElement.scrollWidth
      ) {
        setDisableForward(true);
      }
    }
  }, [multiCarouselElement, scrollPosX]);

  const onForwardClick = () => {
    if (disableForward) {
      onEndClick();
    }
    const offset = single ? 24 : 0;
    multiCarouselElement.scrollTo(
      scrollPosX + multiCarouselElement.clientWidth + offset,
      0,
    );
    if (!disableForward) {
      console.log('hit', currentPos);

      setCurrentPos(currentPos + 1);
    }
  };

  const onBacklick = () => {
    const offset = single ? 24 : 0;
    if (!disableBack && currentPos > 0) {
      setCurrentPos(currentPos - 1);
    }
    multiCarouselElement.scrollTo(
      scrollPosX - multiCarouselElement.clientWidth - offset,
      0,
    );
  };

  return (
    <div className={classes.root}>
      <IconButton
        aria-label="previous button"
        onClick={onBacklick}
        className={clsx(classes.leftScrollBtn)}
        disabled={disableBack}
      >
        <ChevronLeftIcon
          fontSize="large"
          style={{
            color: disableBack ? 'gray' : allItems[currentPos].textColor,
            fontSize: '40',
            // width: '50vw',
            // borderTop: '15px solid red',
          }}
        />
      </IconButton>
      <IconButton
        onClick={onForwardClick}
        className={clsx(classes.rightScrollBtn)}
        // disabled={disableForward}
        aria-label="next button"
      >
        <ChevronRightIcon
          fontSize="large"
          style={{
            color: disableBack
              ? allItems[currentPos].textColor
              : allItems[currentPos].textColor,
            fontSize: '40',
            // width: '50vw',
            // borderTop: '15px solid red',
          }}
        />
      </IconButton>

      <div className={classes.carousel} id={id}>
        {items.map((item, index) => (
          <div key={index} className={classes.carouselItem}>
            {item}
          </div>
        ))}

        <div
          className={clsx(classes.carouselItem, classes.hiddenRightPush)}
        ></div>
      </div>
    </div>
  );
}

MultiCarousel.propTypes = {
  items: PropTypes.array.isRequired,
  iconBase: PropTypes.number,
  width: PropTypes.string.isRequired,
  single: PropTypes.bool,
  identifier: PropTypes.string,
  allItems: PropTypes.any,
  onEndClick: PropTypes.func,
};

export default withWidth()(MultiCarousel);
