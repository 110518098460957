import React from 'react';

//Material ui
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';
import Skeleton from '@material-ui/lab/Skeleton';
import Hidden from '@material-ui/core/Hidden';

const useStyles = makeStyles((theme) => ({
  root: {
    margin: theme.spacing(6, 0),
    padding: theme.spacing(8, 1),
    // width: '100%',
  },
  img: {
    width: 850,
    height: 250,
    [theme.breakpoints.down('sm')]: {
      width: 700,
    },
    [theme.breakpoints.down('xs')]: {
      width: 300,
    },
  },
}));

const IndividualBlogSkeleton = () => {
  const classes = useStyles();
  return (
    <div>
      <Hidden mdDown>
        <div
          style={{
            position: 'fixed',
            width: '10%',
            top: '50%',
            left: '3%',
            zIndex: -1,
          }}
        >
          <Typography>
            <Skeleton animation="wave" />
          </Typography>
          <Typography variant="h6">
            <Skeleton animation="wave" height={28} width="90%" />
          </Typography>
          <Typography style={{ fontSize: '12px' }}>
            <Skeleton animation="wave" height={18} />
          </Typography>
        </div>
      </Hidden>

      <Container maxWidth="md">
        <Grid container className={classes.root}>
          <Grid item className={classes.grid}>
            <Skeleton height={40} width="70%" />
            <Skeleton height={28} width="18%" />
            <Skeleton height={18} width="15%" style={{ marginBottom: 8 }} />
            <Skeleton variant="rect" animation="wave" className={classes.img} />
            <Typography
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <Skeleton height={18} width="70%" style={{ marginBottom: 30 }} />
            </Typography>
            {Array.from({ length: 3 }).map((item, index) => (
              <Skeleton key={index} height={30} width="100%" />
            ))}
            <div style={{ marginTop: 30 }}>
              {Array.from({ length: 4 }).map((item, index) => (
                <Skeleton key={index} height={30} width="100%" />
              ))}
            </div>
          </Grid>
        </Grid>
      </Container>
    </div>
  );
};

export default IndividualBlogSkeleton;
