import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import CustomAccordion from 'components/CustomAccordion';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    minHeight: '100vh',
    [theme.breakpoints.down('md')]: {
      minHeight: '50vh',
    },
    zIndex: 5,
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(5),
    [theme.breakpoints.up('sm')]: {
      padding: theme.spacing(2),
    },
  },

  heading: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: theme.typography.fontWeightRegular,
  },
}));

export default function ResponsiveServices(props) {
  const classes = useStyles();
  const { menus } = props;
  return (
    <div className={classes.root}>
      {menus.map((men, index) => (
        <CustomAccordion
          key={`Acc-${index}`}
          summary={men.menuItem}
          details={men.description}
          lists={men.lists}
        />
      ))}
    </div>
  );
}

ResponsiveServices.propTypes = {
  menus: PropTypes.array.isRequired,
};
