import React from "react";
import "./App.css";
import { ThemeProvider } from "@material-ui/core/styles";

import CssBaseline from "@material-ui/core/CssBaseline";
import theme from "utils/theme";
import RouteManager from "./pages/RouteManager";

function App() {
  return (
    <div>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <RouteManager />
      </ThemeProvider>
    </div>
  );
}

export default App;
