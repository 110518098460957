import React, { useState } from "react";
import PropTypes from "prop-types";
import { useLocation, Link } from "react-router-dom";

import clsx from "clsx";

//Feature
import TopBarButtonCollapse from "./TopBarButtonCollapse";
import useScrollPosition from "./useScrollPosition";

//Component
import NormalImage from "components/NormalImage";

//Mui
import { makeStyles } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import MenuItem from "@material-ui/core/MenuItem";
import { motion } from "framer-motion";

const useStyles = makeStyles((theme) => ({
  root: {
    scrollBehavior: "smooth",
    overflowX: "hidden",
  },
  appBar: {
    background: "transparent",
    // boxShadow: 'block',
  },
  appBarShow: {
    background: "rgba(255,255,255,0)",
  },

  logo: {
    width: 75,
    height: 75,
    objectFit: "contain",
    left: "5rem",
    top: "-1rem",
    // zIndex: 1,
    position: "absolute",
    [theme.breakpoints.down("md")]: {
      left: "2rem",
      top: "-0.5rem",
    },
    [theme.breakpoints.down("sm")]: {
      width: 50,
      height: 50,
      objectFit: "contain",
      left: "1rem",
      top: "0rem",
    },
    [theme.breakpoints.down("xs")]: {
      top: "-1rem",
    },
  },
  menu: {
    position: "absolute",
    right: 50,

    [theme.breakpoints.down("md")]: {
      right: 20,
    },
  },
  buttonBar: {
    [theme.breakpoints.down("md")]: {
      display: "none",
    },
    right: 0,
    position: "relative",
    background: "transparent",
  },
  menuItem: {
    fontWeight: 400,
    fontFamily: "Alegreya Sans SC",
    fontSize: "45px",
    cursor: "pointer",
    color: "#fff",
    padding: theme.spacing(2, 0),
    "&:hover": {
      color: theme.palette.primary.main,
    },
    [theme.breakpoints.down("xs")]: {
      fontSize: "35px",
    },
  },
  toolbar: {
    [theme.breakpoints.up("lg")]: {
      display: "none",
    },
  },
  nav: {
    display: "flex",
    flexWrap: "wrap",
    height: "4ch",
    [theme.breakpoints.down("md")]: {
      display: "none",
    },
  },
  text: {
    objectFit: "contain",
    fontWeight: 400,
    cursor: "pointer",
    fontSize: "16px",
    textDecoration: "none",
    fontFamily: "Alegreya Sans SC",
    lineHeight: "2px",
    color: "#000",
    margin: theme.spacing(3, 2, 0, 2),
    "&:hover": {
      // fontSize: '20px',
      paddingBottom: theme.spacing(1.5),
      borderBottom: `5px solid ${theme.palette.primary.main}`,
    },
  },
  selected: {
    paddingBottom: theme.spacing(1.5),
    borderBottom: `5px solid ${theme.palette.primary.main}`,
  },
  color: {
    color: "#fff",
  },
}));

const Header = (props) => {
  const classes = useStyles();
  const { children } = props;
  const [hideOnScroll, setHideOnScroll] = useState(true);
  // const router = useRouter();
  const location = useLocation();

  useScrollPosition(
    ({ currPos }) => {
      const isShow = currPos.y >= 0;
      if (isShow !== hideOnScroll) setHideOnScroll(isShow);
    },
    [hideOnScroll],
    false,
    false,
    0
  );

  //Routing

  const NavContent = () => {
    return (
      <motion.nav animate className={classes.nav} id="appbar-collapse">
        <Typography
          className={clsx(
            classes.text,
            {
              [classes.selected]: location.pathname === "/",
            },
            {
              [classes.color]: location.pathname === "/blogs",
            },
            {
              [classes.toolbar]: hideOnScroll === false,
            }
          )}
          component={Link}
          to="/"
          variant="body1"
        >
          {" "}
          Home
        </Typography>

        <Typography
          className={clsx(
            classes.text,
            {
              [classes.selected]: location.pathname === "/portfolio",
            },
            {
              [classes.color]: location.pathname === "/blogs",
            },
            {
              [classes.toolbar]: hideOnScroll === false,
            }
          )}
          variant="body1"
          component={Link}
          to="/portfolio"
        >
          {" "}
          Portfolio
        </Typography>
        <Typography
          className={clsx(
            classes.text,
            {
              [classes.selected]: location.pathname === "/about",
            },
            {
              [classes.color]: location.pathname === "/blogs",
            },
            {
              [classes.toolbar]: hideOnScroll === false,
            }
          )}
          component={Link}
          to="/about"
          variant="body1"
        >
          {" "}
          About
        </Typography>
        <Typography
          className={clsx(
            classes.text,
            {
              [classes.selected]: location.pathname === "/blogs",
            },
            {
              [classes.color]: location.pathname === "/blogs",
            },
            {
              [classes.toolbar]: hideOnScroll === false,
            }
          )}
          component={Link}
          to="/blogs"
          variant="body1"
        >
          {" "}
          Blogs
        </Typography>
      </motion.nav>
    );
  };

  const OtherPageContent = () => {
    return (
      <Toolbar>
        <Link to="/">
          <div
            style={{
              position: "relative",
              width: 25,
              cursor: "pointer",
            }}
          >
            <NormalImage
              alt="251 logo"
              src={"/images/logo/251logo.svg"}
              className={classes.logo}
            />
          </div>
        </Link>

        <div style={{ flexGrow: 0.95 }} />
        <NavContent />
        <div
          className={clsx(
            {
              [classes.toolbar]: hideOnScroll === true,
            },
            classes.menu
          )}
        >
          <TopBarButtonCollapse>
            {location.pathname !== "/" && (
              <MenuItem>
                <Typography
                  component={Link}
                  to="/"
                  className={classes.menuItem}
                >
                  Home
                </Typography>
              </MenuItem>
            )}
            <MenuItem>
              <Typography
                component={Link}
                to="/portfolio"
                className={classes.menuItem}
              >
                Portfolio
              </Typography>
            </MenuItem>
            <MenuItem>
              <Typography
                className={classes.menuItem}
                component={Link}
                to="/blogs"
              >
                Blogs
              </Typography>
            </MenuItem>
            <MenuItem>
              <Typography
                className={classes.menuItem}
                component={Link}
                to="/about"
              >
                About Us
              </Typography>
            </MenuItem>
          </TopBarButtonCollapse>
        </div>
      </Toolbar>
    );
  };

  return (
    <div>
      <AppBar elevation={0} className={classes.appBarShow}>
        <OtherPageContent />
      </AppBar>

      {children}
    </div>
  );
};

Header.propTypes = {
  children: PropTypes.any,
};

export default Header;
