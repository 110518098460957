import React from 'react';
import homeData from 'data/home';
import worksData from 'data/work';

//Feature
import Clients from 'features/home/Clients';
import OurWork from './OurWork';

const Portfolio = () => {
  return (
    <div>
      <OurWork worksData={worksData['companies']} />
      <Clients clientsData={homeData['clients']} />
    </div>
  );
};

export default Portfolio;
