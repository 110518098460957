import React from "react";
import PropTypes from "prop-types";

import { useHistory } from "react-router-dom";

//Component
import Title from "components/Title";
// import IndividualCompanyCard from 'components/work/IndividualCompanyCard';

//Material ui
import { makeStyles } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";

const useStyles = makeStyles((theme) => ({
  root: {
    // width: '100%',
    margin: theme.spacing(10, 2),
    [theme.breakpoints.down("sm")]: {
      margin: theme.spacing(10, 1),
    },
  },
  rootdiv: {
    columnCount: 3,
    [theme.breakpoints.down("sm")]: {
      columnCount: 2,
    },
    [theme.breakpoints.down("xs")]: {
      columnCount: 1,
    },
    margin: theme.spacing(5, 0),
  },
  imgdiv: {
    position: "relative",
    width: "100%",
    overflow: "hidden",
    margin: "0 auto",
    cursor: "pointer",
    marginBottom: "1em",
  },
  img: {
    width: "100%",
    transition: "0.3s all ease-in-out",

    "&:hover": {
      transform: "scale(1.075)",
    },
  },
  title: {
    color: "#FFF",
    fontSize: "30px",
  },
  subtitle: {
    color: "#FFF",
  },
  overlay: {
    position: "absolute",
    transition: "all 0.9s ease-in-out",
    opacity: 0,
    backgroundColor: "hsl(0, 0%, 10%)",
    height: "100%",
    width: "100%",
    display: "grid",
    placeItems: "center",
    // justifyContent: 'center',
    top: 0,
    left: 0,
    paddingBottom: "1em",
    cursor: "pointer",
    [theme.breakpoints.down("md")]: {
      opacity: 0.6,
    },
    "&:hover": {
      opacity: 0.8,
    },
  },
}));

const OurWork = (props) => {
  const classes = useStyles();
  const { worksData } = props;
  const history = useHistory();
  const handleClick = (alias) => {
    if (alias) {
      history.push(`/project/${alias}`);
    }
  };
  return (
    <div className={classes.root}>
      <Box display="flex" justifyContent="center" flexDirection="column">
        <Title dark secondaryTitle="We've Worked with Some of the Best">
          Our Work
        </Title>
        <div className={classes.rootdiv}>
          {worksData.map((company, index) => (
            <div
              key={index}
              className={classes.imgdiv}
              onClick={() => handleClick(company.alias)}
            >
              <img
                src={company.img}
                alt={`${company.companyName}`}
                className={classes.img}
              />

              <div className={classes.overlay}>
                <div>
                  <Typography className={classes.title} align="center">
                    {company.companyName}
                  </Typography>
                  <Typography
                    variant="body1"
                    align="center"
                    className={classes.subtitle}
                  >
                    {company.worksDone}
                  </Typography>
                </div>
              </div>
            </div>
          ))}
        </div>
      </Box>
    </div>
  );
};

OurWork.propTypes = {
  worksData: PropTypes.array,
};

export default OurWork;
