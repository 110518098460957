import React from "react";
import WithAuth from "utils/WithAuth";
import Layout from "features/admin/layout/Layout";
import BlogComposer from "features/admin/blog/BlogComposer";
export default function AdminBlogDetail() {
  return (
    <WithAuth>
      <Layout>
        <BlogComposer />
      </Layout>
    </WithAuth>
  );
}
