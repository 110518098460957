import React from "react";
import homeData from "data/home";
//Feature
import Banner from "./Banner";
import SomeWorks from "./someWorks/SomeWorks";
import Services from "./services/Services";
import Clients from "./Clients";
import Blog from "./blog/Blog";

const Home = () => {
  return (
    <div>
      <Banner data={homeData["banner"]} />
      <SomeWorks data={homeData["someWorks"]} />
      <Services servicesData={homeData["services"]} />
      <Clients clientsData={homeData["clients"]} />
      <Blog />
    </div>
  );
};

export default Home;
