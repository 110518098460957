import React from 'react';
import PropTypes from 'prop-types';

//Material ui
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';

const useStyles = makeStyles((theme) => ({
  root: {
    margin: theme.spacing(2, 0),
  },
  paragraph: {
    fontSize: '19px',
    [theme.breakpoints.up('xl')]: {
      fontSize: '21px',
    },

    // lineHeight: 1.6,
  },
}));

const IndividualBlogParagraph = (props) => {
  const { paragraph } = props;
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Typography className={classes.paragraph}>{paragraph}</Typography>
    </div>
  );
};

IndividualBlogParagraph.propTypes = {
  paragraph: PropTypes.string,
};

export default IndividualBlogParagraph;
