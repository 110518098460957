import React, { useState, useEffect } from "react";
import TextareaAutosize from "@material-ui/core/TextareaAutosize";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import IconButton from "@material-ui/core/IconButton";
import EditIcon from "@material-ui/icons/Edit";
import DoneIcon from "@material-ui/icons/Done";
import CancelIcon from "@material-ui/icons/Cancel";
import DeleteIcon from "@material-ui/icons/Delete";
import PropTypes from "prop-types";
import { database } from "config/fire-config";
import { useParams } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  root: {
    margin: theme.spacing(2),
    display: "grid",
    placeItems: "center",
  },
  paraItem: {
    width: "60%",
    outline: "none",
    resize: "none",
    padding: "10px",
    borderRadius: "10px",
    [theme.breakpoints.down("lg")]: {
      width: "70%",
    },
    [theme.breakpoints.down("md")]: {
      width: "80%",
    },
    [theme.breakpoints.down("xs")]: {
      width: "95%",
    },
  },
  paraCard: {
    padding: theme.spacing(2),
    width: "60%",
    borderRadius: "10px",
    [theme.breakpoints.down("lg")]: {
      width: "70%",
    },
    [theme.breakpoints.down("md")]: {
      width: "80%",
    },
    [theme.breakpoints.down("xs")]: {
      width: "95%",
    },
  },
  controllerBox: {
    width: "60%",
    [theme.breakpoints.down("lg")]: {
      width: "70%",
    },
    [theme.breakpoints.down("md")]: {
      width: "80%",
    },
    [theme.breakpoints.down("xs")]: {
      width: "95%",
    },
  },
  doneIcon: {
    marginLeft: theme.spacing(3),
    color: theme.palette.success.main,
  },
  cancelIcon: {
    marginLeft: theme.spacing(1),
    color: theme.palette.error.main,
  },
  deleteIcon: {
    marginLeft: theme.spacing(4),
    "&:hover": {
      color: theme.palette.error.dark,
    },
  },
  editBox: {
    cursor: "pointer",
    margin: theme.spacing(1),
  },
}));

export default function ParagraphItem(props) {
  const classes = useStyles();
  const { item, setDeletedItem, setItemLoading, index } = props;
  const [enableEdit, setEnableEdit] = useState(false);
  const [content, setContent] = useState("");
  const { blogId } = useParams();
  useEffect(() => {
    setContent(item.value);
    if (item.value === "") {
      setEnableEdit(true);
    }
  }, [item]);

  const handleSaveClick = () => {
    if (item) {
      setItemLoading(true);
      database
        .ref("blogs/" + blogId + "/blogItems/" + index + "/value")
        .set(content)
        .then((data) => {
          setItemLoading(false);
          setEnableEdit(false);
        })
        .catch((err) => console.log("error creating item", err));
    }
  };
  return (
    <div className={classes.root}>
      {enableEdit ? (
        <React.Fragment>
          <TextareaAutosize
            rowsMin={15}
            placeholder="Paragraph-1"
            value={content}
            onChange={(e) => setContent(e.target.value)}
            className={classes.paraItem}
          />
          <Box
            display="flex"
            justifyContent="flex-end"
            className={classes.controllerBox}
          >
            <IconButton
              aria-label="done button"
              className={classes.doneIcon}
              onClick={handleSaveClick}
            >
              <DoneIcon />
            </IconButton>
            <IconButton
              aria-label="cancel button"
              className={classes.cancelIcon}
              onClick={() => setEnableEdit(false)}
            >
              <CancelIcon />
            </IconButton>
            <IconButton
              aria-label="delete button"
              className={classes.deleteIcon}
              onClick={() => setDeletedItem(item)}
            >
              <DeleteIcon />
            </IconButton>
          </Box>
        </React.Fragment>
      ) : (
        <React.Fragment>
          <Paper className={classes.paraCard}>
            <Box
              display="flex"
              justifyContent="flex-end"
              className={classes.editBox}
            >
              <IconButton
                aria-label="edit button"
                onClick={() => setEnableEdit(true)}
              >
                <EditIcon color="secondary" />
              </IconButton>
            </Box>
            <Typography variant="body1">{content}</Typography>
          </Paper>
        </React.Fragment>
      )}
    </div>
  );
}

ParagraphItem.propTypes = {
  setDeletedItem: PropTypes.func.isRequired,
  setItemLoading: PropTypes.func.isRequired,
  item: PropTypes.any.isRequired,
  index: PropTypes.number.isRequired,
};
