import React, { useState, useEffect, useRef } from "react";
import { database, storage } from "config/fire-config";
import AddBlogItem from "./AddBlogItem";
import ParagraphItem from "./ParagraphItem";
import ImageItem from "./ImageItem";
import { useParams } from "react-router-dom";
import BlogTitle from "./BlogTitle";
import FullPageLoading from "components/loading/FullPageLoading";
import SubHeadingItem from "./SubHeadingItem";

export default function AddBlogItemContainer() {
  const [blogItems, setBlogItems] = useState([]);
  const [blogMeta, setBlogMeta] = useState({});
  const [newBlogItem, setNewBlogItem] = useState(null);

  const [deletedBlogItem, setDeletedBlogItem] = useState(null);
  const [itemLoading, setItemLoading] = useState(null);
  const itemEnd = useRef(null);
  const { blogId } = useParams();

  useEffect(() => {
    setItemLoading(true);
    let blogItemsRef = database.ref("blogs/" + blogId);
    let blogRefData = blogItemsRef.on("value", (snapshot) => {
      setItemLoading(false);
      const blogItemsData = snapshot.val().blogItems;
      const blogMeta = snapshot.val().meta;
      setBlogItems(blogItemsData);
      setBlogMeta(blogMeta);
    });
    return () => {
      blogItemsRef.off("value", blogRefData);
    };
  }, []);

  useEffect(() => {
    if (newBlogItem) {
      // console.log('new blog Item', newBlogItem);
      setItemLoading(true);
      let spreadItems = blogItems ? blogItems : [];
      const tempBlogItems = spreadItems;

      tempBlogItems.push(newBlogItem);
      setBlogItems(tempBlogItems);

      console.log("temp blog Items", tempBlogItems);
      setNewBlogItem(null);
      database
        .ref("blogs/" + blogId + "/blogItems")
        .set(tempBlogItems)
        .then((data) => setItemLoading(false))
        .catch((err) => console.log("error creating item", err));
    }

    setTimeout(() => {
      itemEnd.current.scrollIntoView({ behavior: "smooth" });
    }, 100);
  }, [newBlogItem]);

  useEffect(() => {
    if (deletedBlogItem) {
      if (deletedBlogItem.type === "image") {
        // console.log('deleted blog Items', deletedBlogItem);
        let spreadItems = blogItems ? blogItems : [];
        if (spreadItems) {
          const newBlogItems = spreadItems.filter(
            (item) => item.id !== deletedBlogItem.id
          );
          setBlogItems(newBlogItems);
          setDeletedBlogItem(null);
          if (deletedBlogItem.value !== "")
            storage.ref(deletedBlogItem.value).delete();

          database
            .ref("blogs/" + blogId + "/blogItems")
            .set(newBlogItems)
            .then((data) => setItemLoading(false))
            .catch((err) => console.log("error creating item", err));
        }
      } else {
        // console.log('deleted blog Items', deletedBlogItem);
        // console.log('deleted blog items', blogItems);
        let spreadItems = blogItems ? blogItems : [];
        if (spreadItems) {
          const newBlogItems = spreadItems.filter(
            (item) => item.id !== deletedBlogItem.id
          );
          setBlogItems(newBlogItems);
          setDeletedBlogItem(null);
          database
            .ref("blogs/" + blogId + "/blogItems")
            .set(newBlogItems)
            .then((data) => setItemLoading(false))
            .catch((err) => console.log("error creating item", err));
        }
      }
    }
  }, [deletedBlogItem]);
  function getItemComponent(item, index) {
    if (item.type === "paragraph") {
      return (
        <ParagraphItem
          index={index}
          setDeletedItem={setDeletedBlogItem}
          item={item}
          setItemLoading={setItemLoading}
        />
      );
    } else if (item.type === "subHeading") {
      return (
        <SubHeadingItem
          setDeletedItem={setDeletedBlogItem}
          item={item}
          index={index}
          setItemLoading={setItemLoading}
        />
      );
    } else if (item.type === "image") {
      return (
        <ImageItem
          setDeletedItem={setDeletedBlogItem}
          item={item}
          index={index}
          setItemLoading={setItemLoading}
        />
      );
    } else {
      return <React.Fragment></React.Fragment>;
    }
  }
  return (
    <div>
      <BlogTitle meta={blogMeta} setItemLoading={setItemLoading} />
      {blogItems &&
        blogItems.length > 0 &&
        blogItems.map((item, index) => (
          <div key={`item-${index}`}>{getItemComponent(item, index)}</div>
        ))}
      <AddBlogItem setNewBlogItem={setNewBlogItem} />
      <div
        style={{ float: "left", clear: "both" }}
        ref={(el) => {
          itemEnd.current = el;
        }}
      ></div>
      {itemLoading && <FullPageLoading message="Loading..." />}
    </div>
  );
}
