import React from "react";
import { Switch, BrowserRouter as Router, Route } from "react-router-dom";
import HomePage from "./HomePage";
import AboutPage from "./AboutPage";
import PortfolioPage from "./PortfolioPage";
import BlogListPage from "./blogs/BlogListPage";
import BlogDetailPage from "./blogs/BlogDetailPage";
import ProjectDetailPage from "./project/ProjectDetailPage";
import AdminHomePage from "./admin/AdminHomePage";
import AdminLogin from "./admin/AdminLogin";
import AdminBlogDetail from "./admin/blogs/AdminBlogDetail";
export default function RouteManager() {
  return (
    <Router>
      <Switch>
        <Route path="/" component={HomePage} exact />
        <Route path="/about" component={AboutPage} exact />
        <Route path="/portfolio" component={PortfolioPage} exact />
        <Route path="/blogs" component={BlogListPage} exact />
        <Route path="/blogs/:blogid" component={BlogDetailPage} exact />
        <Route path="/project/:projectid" component={ProjectDetailPage} exact />
        <Route path="/admin" component={AdminHomePage} exact />
        <Route path="/admin/login" component={AdminLogin} exact />
        <Route path="/admin/blogs/:blogId" component={AdminBlogDetail} exact />

        {/* <Route path="/blogs/:id" component={BlogDetail} exact />
        <Route path="/blogs/:id/place" component={PlaceBlog} exact />
        <Route path="/blogs/:id/:slug" component={BlogSlug} exact /> */}
      </Switch>
    </Router>
  );
}
