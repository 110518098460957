const home = {
  banner: {
    moto1: 'We Build Brands.',
    moto2: 'Unique is in our Nature.',
    description: `We are full-fledged communications and public relations firm in Ethiopia, with offices in Addis Ababa, Washington DC, and Metropolitan Area. At 251 Communications, we manage our international and local clients’ communications, marketing, research, media, PR, branding, and production needs.`,
  },
  someWorks: {
    companies: [
      {
        companyName: 'Buna Pen',
        worksDone: 'Branding, Logos and Video',
        img: '/images/buna.jpg',
        alias: 'buna',
        layerColor: 'rgba(0,0,0,0.3)',
        textColor: '#3b86f8',
      },
      {
        companyName: 'Yegna',
        worksDone: 'Campaign',
        img: '/images/yegna.jpg',
        alias: 'yegna',
        layerColor: 'rgb(120, 25, 14, 0.4)',
        textColor: '#18e7c8',
      },
      {
        companyName: 'Malta Guiness',
        worksDone: '360  Campaign',
        img: '/images/malta.jpg',
        alias: 'malta',
        layerColor: 'rgb(0, 0, 0, 0.6)',
        textColor: '#f02427',
      },
      {
        companyName: 'Etete Dairy',
        worksDone: 'Branding, Logos and Video',
        img: '/images/etete.jpg',
        alias: 'etete',
        layerColor: 'rgb(14, 32, 81, 0.5)',
        textColor: '#f1dfae',
      },
    ],
  },
  services: {
    menus: [
      {
        menuItem: 'Marketing',
        description:
          'Connecting our clients with their consumers, Devoted not just to research alone, our teams dwell at the heart of consumers, and explore the market from within.',
        lists: [
          'Strategic Planning',
          'Consumer Behavior Mapping',
          'Media Strategy',
          'Media Planning,',
          'Placement & Monitoring',
          'Communication Strategy',
          'Product Positioning',
          'Copywriting',
          'Integrated ATL and BTL Campaigns',
        ],
      },
      {
        menuItem: 'PR & Media',
        description:
          'We are the premier PR agency in Ethiopia, with access to a number of resources along with knowledge of government policy, public perception, and how to support marketing promotion strategies.',
        lists: [
          'Rich Network of Media',
          'Endorsed by Aegis Media',
          'Close Connection With Saracen Media',
        ],
      },
      {
        menuItem: 'Design',
        description:
          'Our design philosophy is “Very simple-communication that calls for action!” And that is what we strive for at all costs.',
        lists: [
          'Photography',
          'Film & Video',
          'Post Production',
          'Illustration',
          'Architectural Modeling',
          'Product Design',
          'Music Composition',
          'Radio & TVC Production',
          'Digital and Offset Printing',
          'Large Printing',
        ],
      },
      {
        menuItem: 'Consumer Insights',
        description:
          '251 partners with Nairobi-based consumer insight research company and has access to their tools and resources. This partnership allows us to provide effective market research for our clients.',
        lists: ['Market Research', 'Data Preparation'],
      },

      {
        menuItem: 'OOH Production',
        description:
          'With limited media space in Ethiopia, we are always innovating strategic media placement options. From traditional mediums such as wall-branding, billboards, bus-branding, bill folders, posters, etc to digital mediums and various experiential mediums.',
        lists: [],
      },
    ],
  },
  clients: [
    {
      companyName: 'MTN',
      companyLogo: '/images/mtn.svg',
      link: 'https://www.mtn.com',
    },
    {
      companyName: 'usaid',
      companyLogo: '/images/usaid.svg',
      link: 'https://www.usaid.gov',
    },
    {
      companyName: 'Western Union',
      companyLogo: '/images/WU.svg',
      link: 'https://www.westernunion.com',
    },
    {
      companyName: 'Africa CEO Forum',
      companyLogo: '/images/africa.svg',
      link: 'https://www.theafricaceoforum.com',
    },
    {
      companyName: 'Coca Cola',
      companyLogo: '/images/coca.svg',
      link: 'http://et.coca-cola.com/',
    },

    {
      companyName: 'The Economist',
      companyLogo: '/images/theeconomist.svg',
      link: 'https://www.economist.com',
    },
    {
      companyName: 'Huawei',
      companyLogo: '/images/huawei.svg',
      link: 'https://support.huawei.com',
    },
    {
      companyName: 'Hp',
      companyLogo: '/images/hp.svg',
      link: 'https://www.hp.com',
    },
    {
      companyName: 'DHL',
      companyLogo: '/images/dhl.svg',
      link: 'https://www.dhl.com',
    },

    {
      companyName: 'Unicef',
      companyLogo: '/images/unicef.svg',
      link: 'https://www.unicef.org',
    },

    {
      companyName: 'Bajaj',
      companyLogo: '/images/bajaj.svg',
      link: 'https://www.bajajauto.com',
    },

    {
      companyName: 'Total',
      companyLogo: '/images/total.svg',
      link: 'https://www.totalethiopia.com',
    },
  ],
};

export default home;
