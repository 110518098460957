import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography';
import Fade from '@material-ui/core/Fade';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  root: {
    position: 'relative',
    display: 'grid',
    placeItems: 'center',
  },
  desc: {
    width: '70%',

    padding: theme.spacing(3),
    [theme.breakpoints.down('md')]: {
      padding: theme.spacing(1),
    },
  },
  img: {
    background: '#fff',
    width: '50%',
    height: 'auto',
    [theme.breakpoints.down('md')]: {
      width: '90%',
    },
  },
}));

function TeamMemberCard(props) {
  const classes = useStyles();
  const [hovered, setHovered] = useState(false);
  const { firstPicture, secondPicture, fullName, position } = props;

  useEffect(() => {
    const img1 = new Image();
    const img2 = new Image();
    img1.src = firstPicture;
    img2.src = secondPicture;
  }, [hovered]);
  return (
    <div
      className={classes.root}
      onMouseEnter={() => setHovered(true)}
      onMouseLeave={() => setHovered(false)}
    >
      <img
        src={hovered ? secondPicture : firstPicture}
        alt={`${fullName}`}
        className={classes.img}
      />
      {/* {hovered && (
        <img src={secondPicture} alt={`${fullName}`} className={classes.img} />
      )}
      {!hovered && (
        <img src={firstPicture} alt={`${fullName}`} className={classes.img} />
      )} */}

      <Fade in={true} timeout={2000}>
        <div className={classes.desc}>
          <Typography
            variant="h5"
            align="center"
            style={{ fontWeight: 'w500' }}
          >
            {fullName}
          </Typography>
          <Typography variant="subtitle1" align="center">
            {position}
          </Typography>
        </div>
      </Fade>
    </div>
  );
}

TeamMemberCard.propTypes = {
  firstPicture: PropTypes.any.isRequired,
  secondPicture: PropTypes.any.isRequired,
  fullName: PropTypes.string.isRequired,
  position: PropTypes.string.isRequired,
};

export default TeamMemberCard;
