import firebase from "firebase/app";
import "firebase/database";
import "firebase/storage";
import "firebase/auth";

// console.log("AUTH DOMAIN", process.env.REACT_APP_AUTH_DOMAIN);
const firebaseConfig = {
  apiKey: process.env.REACT_APP_API_KEY,
  authDomain: process.env.REACT_APP_AUTH_DOMAIN,
  databaseURL: process.env.REACT_APP_DATABASE_URL,
  projectId: process.env.REACT_APP_PROJECT_ID,
  storageBucket: process.env.REACT_APP_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_APP_ID,
};
let database;
let auth;
let storage;

try {
  firebase.initializeApp(firebaseConfig);

  database = firebase.database();
  auth = firebase.auth();
  storage = firebase.storage();
} catch (err) {
  if (!/already exists/.test(err.message)) {
    console.error("Firebase initialization error", err.stack);
  }
}

export { database, auth, storage };
