import React from 'react';

//Feature
import UpperFooter from './UpperFooter';
import LowerFooter from './LowerFooter';

const Footer = () => {
  return (
    <div>
      {/* <UpperFooter /> */}
      <LowerFooter />
    </div>
  );
};

export default Footer;
