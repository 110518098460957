import React from "react";

//Material ui
import { withStyles } from "@material-ui/core/styles";
import Fade from "@material-ui/core/Fade";
import IconButton from "@material-ui/core/IconButton";

//Icon

import CloseIcon from "@material-ui/icons/Close";

const styles = (theme) => ({
  menuIcon: {
    backgroundColor: "transparent",

    color: theme.palette.secondary.main,
    marginTop: theme.spacing(2),
    [theme.breakpoints.down("md")]: {
      marginTop: theme.spacing(4),
    },
    [theme.breakpoints.down("xs")]: {
      marginTop: theme.spacing(1),
    },
    transition: "transform 0.5s",
    "&:hover": {
      transform: "scale(1.3)",

      transition: "transform 0.5s",
      backgroundColor: "transparent",
    },
    // paddingTop: theme.spacing(4),
  },
  menuSvg: {
    height: 50,
    width: 50,
    [theme.breakpoints.down("lg")]: {
      height: 43,
      width: 43,
    },
    [theme.breakpoints.down("md")]: {
      height: 40,
      width: 40,
    },
    [theme.breakpoints.down("xs")]: {
      height: 30,
      width: 30,
    },
  },
  buttonCollapse: {
    // [theme.breakpoints.up('lg')]: {
    //   display: 'none',
    // },
    // margin: '10px',
    boxShadow: "none",
  },
  menu: {
    position: "fixed",
    left: 0,
    right: 0,
    bottom: 0,
    top: 0,
    background: "#000",
    display: "grid",
    placeItems: "center",
  },
  iconbutton: {
    color: theme.palette.primary.main,
    position: "absolute",
    right: 45,
    transition: "transform 0.5s",
    top: 7,
    color: "#fff",
    "&:hover": {
      color: theme.palette.primary.main,
      transform: "scale(1.1)",

      transition: "transform 0.5s",
    },
    [theme.breakpoints.down("md")]: {
      top: 10,
      right: 17,
    },
    [theme.breakpoints.down("sm")]: {
      right: 20,
      top: 12,
    },
    [theme.breakpoints.down("xs")]: {
      right: 18,
      top: 8,
    },
  },
  icon: {
    fontSize: "45px",
    [theme.breakpoints.down("sm")]: {
      fontSize: "45px",
    },
    [theme.breakpoints.down("xs")]: {
      fontSize: "35px",
    },
  },
});

class TopBarButtonCollapse extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      anchorEl: null,
      checked: false,
    };
    this.handleMenu = this.handleMenu.bind(this);
  }
  handleMenu = (event) => {
    this.setState({ anchorEl: event.currentTarget });
    this.setState({ checked: true });
  };
  handleClose = () => {
    this.setState({ anchorEl: null });
    this.setState({ checked: false });
  };
  render() {
    const { classes } = this.props;
    const { anchorEl, checked } = this.state;
    const open = Boolean(anchorEl);

    return (
      <div className={classes.buttonCollapse}>
        <IconButton
          aria-label="Top Menu Bar"
          className={classes.menuIcon}
          onClick={this.handleMenu}
        >
          <img
            src={"/images/logo/menu.svg"}
            className={classes.menuSvg}
            alt="menu icon image"
          />
        </IconButton>

        <Fade in={checked} timeout={1000}>
          <div className={classes.menu}>
            <IconButton
              aria-label="Top Menu Bar Close Button"
              className={classes.iconbutton}
              onClick={this.handleClose}
            >
              <CloseIcon className={classes.icon} />
            </IconButton>
            <div>{this.props.children}</div>
          </div>
        </Fade>
      </div>
    );
  }
}
export default withStyles(styles)(TopBarButtonCollapse);
