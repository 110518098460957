import React from 'react';
import OurTeam from './OurTeam';
import aboutData from 'data/about';
import WhoWeAre from './WhoWeAre';

export default function About() {
  return (
    <div>
      <WhoWeAre description={aboutData.whoWeAre} />
      <OurTeam aboutData={aboutData} />
    </div>
  );
}
