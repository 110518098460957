import React from 'react';
import withWidth from '@material-ui/core/withWidth';
import PropTypes from 'prop-types';
import ResponsiveSomeWorks from './ResponsiveSomeWorks';
import DesktopSomeWorks from './DesktopSomeWorks';

function SomeWorks(props) {
  const { width, data } = props;

  return (
    <div>
      {width === 'xs' || width === 'sm' || width === 'md' ? (
        <ResponsiveSomeWorks companies={data.companies} />
      ) : (
        <DesktopSomeWorks companies={data.companies} />
      )}
    </div>
  );
}

SomeWorks.propTypes = {
  width: PropTypes.string.isRequired,
  data: PropTypes.object,
};

export default withWidth()(SomeWorks);
