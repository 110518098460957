import React from "react";
import { useParams } from "react-router-dom";
import projectData from "data/project";
import ProjectDescription from "./ProjectDescription";
import ProjectHeader from "./ProjectHeader";
const IndividualProject = () => {
  const { projectid } = useParams();

  return (
    <div>
      <ProjectHeader
        projectData={projectData["companies"][`${projectid.toUpperCase()}`]}
      />
      <ProjectDescription
        projectData={projectData["companies"][`${projectid.toUpperCase()}`]}
      />
    </div>
  );
};

export default IndividualProject;
