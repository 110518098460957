import React, { useState } from "react";
import PropTypes from "prop-types";

import { useHistory } from "react-router-dom";

//Component
import CustomButton from "components/CustomButton";
import Title from "components/Title";

//Mui
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import Divider from "@material-ui/core/Divider";
import Hidden from "@material-ui/core/Hidden";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    height: "85vh",
    [theme.breakpoints.down("lg")]: {
      height: "100vh",
    },
    zIndex: 2,
    position: "relative",
    overflow: "hidden",
  },
  grid: {
    height: "65%",
    padding: "4rem 0 0 0rem",
    [theme.breakpoints.down("lg")]: {
      padding: "2rem 0 0 0rem",
    },
    [theme.breakpoints.down("sm")]: {
      alignItems: "left",
    },
  },
  content: {
    display: "flex",
    justifyContent: "center",
    // alignItems: 'center',
  },
  img: {
    width: "100%",
    // maxHeight: '100vh',
    filter: "contrast(50%)",
    minHeight: "85vh",
    [theme.breakpoints.down("lg")]: {
      minHeight: "100vh",
    },
    zIndex: -2,
    position: "absolute",

    [theme.breakpoints.down("md")]: {
      height: "100%",
      objectFit: "cover",
    },
  },

  viewMore: {
    position: "absolute",
    bottom: "60px",
    left: "50%",
    [theme.breakpoints.down("lg")]: {
      position: "absolute",
      bottom: "40px",
      left: "50%",
    },
  },

  box: {
    display: "flex",
    flexWrap: "wrap",
    alignItems: "center",
    minWidth: "40vw",
    [theme.breakpoints.down("lg")]: {
      minWidth: "55vw",
    },
  },
  subtitle: {
    color: "rgba(255,255,255,0.5)",
    fontFamily: "Alegreya Sans SC",
    fontWeight: "500",
    marginRight: "4em",
    fontSize: "40px",
    cursor: "pointer",
    [theme.breakpoints.down("lg")]: {
      fontSize: "40px",
    },
    [theme.breakpoints.down("sm")]: {
      fontSize: "25px",
    },
  },
  description: {
    color: "#fff",
    fontWeight: "bolder",
    marginLeft: theme.spacing(3),
    fontFamily: "Alegreya Sans SC",
  },

  layer: {
    position: "absolute",
    height: "85vh",
    width: "100%",
    [theme.breakpoints.down("lg")]: {
      minHeight: "100vh",
    },

    zIndex: -1,
  },
  divider: {
    height: 28,
    margin: 4,
    backgroundColor: "#FFF",
    width: 5,
  },
  title: {
    // marginBottom: theme.spacing(1),
    // [theme.breakpoints.down('lg')]: {
    //   marginBottom: theme.spacing(1),
    // },
    // [theme.breakpoints.down('md')]: {
    //   marginBottom: theme.spacing(5),
    // },
  },
}));

const DesktopSomeWorks = (props) => {
  const classes = useStyles();
  const { companies } = props;
  const [company, setCompany] = useState(companies[0]);
  const history = useHistory();

  const handleHover = (company) => {
    setCompany(company);
  };

  const handlePortfolioClick = () => {
    history.push("/portfolio");
  };

  const handleClick = (id) => {
    if (id !== "") {
      history.push(`/project/${id}`);
    }
  };

  return (
    <div id="someworks" className={classes.root}>
      {companies.map((item, index) => (
        <div key={`CompanyCover-${index}`}>
          {company === item && (
            <img
              style={{ background: "#000" }}
              src={`${item.img}`}
              alt={`${item.alias}`}
              className={classes.img}
            />
          )}
        </div>
      ))}
      <div
        className={classes.layer}
        style={{
          background: `${company.layerColor}`,
        }}
      ></div>
      <div className={classes.root} style={{ position: "relative" }}>
        <Title
          dark={false}
          className={classes.title}
          secondaryTitle="Passion is in Our Recipe"
        >
          Our Work
        </Title>

        <Grid
          container
          className={classes.grid}
          direction="column"
          justify="space-around"
          alignItems="center"
        >
          <Grid item>
            {companies.map((item, index) => (
              <div key={`someWork-${index}`} style={{ marginBottom: "10px" }}>
                {company === item ? (
                  <Box className={classes.box}>
                    <Typography
                      onClick={() => handleClick(item.alias)}
                      className={classes.subtitle}
                      style={{
                        color: `${company.textColor}`,
                      }}
                    >
                      {item.companyName}
                    </Typography>

                    <Hidden smDown>
                      <Divider
                        className={classes.divider}
                        orientation="vertical"
                      />
                    </Hidden>

                    <Typography variant="h6" className={classes.description}>
                      {`${item.worksDone}`}
                    </Typography>
                  </Box>
                ) : (
                  <Box
                    onClick={() => handleClick(item.alias)}
                    className={classes.box}
                  >
                    <Typography
                      onMouseEnter={() => handleHover(item)}
                      className={classes.subtitle}
                    >
                      {`${item.companyName}`}
                    </Typography>
                  </Box>
                )}
              </div>
            ))}
          </Grid>
        </Grid>
        <Box
          display="flex"
          justifyContent="center"
          className={classes.viewMore}
        >
          <CustomButton
            variant="outlined"
            color="#fff"
            onClick={handlePortfolioClick}
            style={{ position: "relative", left: "-50%" }}
          >
            View More
          </CustomButton>
        </Box>
      </div>
    </div>
  );
};

DesktopSomeWorks.propTypes = {
  companies: PropTypes.array,
};

export default DesktopSomeWorks;
