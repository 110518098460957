import React, { useState, useEffect } from "react";
import { database, storage } from "config/fire-config";
import ImageUploader from "components/admin/ImageUploader";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import IconButton from "@material-ui/core/IconButton";
import DoneIcon from "@material-ui/icons/Done";
import CancelIcon from "@material-ui/icons/Cancel";
import DeleteIcon from "@material-ui/icons/Delete";
import PropTypes from "prop-types";
import { useParams } from "react-router-dom";
import EditIcon from "@material-ui/icons/Edit";
import NormalImage from "components/NormalImage";
import BootstrapInput from "components/BootStrapInput";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  root: {
    margin: theme.spacing(2),
    display: "grid",
    placeItems: "center",
  },
  doneIcon: {
    marginLeft: theme.spacing(3),
    color: theme.palette.success.main,
  },
  cancelIcon: {
    marginLeft: theme.spacing(1),
    color: theme.palette.error.main,
  },
  deleteIcon: {
    marginLeft: theme.spacing(4),
    "&:hover": {
      color: theme.palette.error.dark,
    },
  },
  controllerBox: {
    width: "60%",
    [theme.breakpoints.down("lg")]: {
      width: "70%",
    },
    [theme.breakpoints.down("md")]: {
      width: "80%",
    },
    [theme.breakpoints.down("xs")]: {
      width: "95%",
    },
  },
}));

export default function ImageItem(props) {
  const classes = useStyles();
  const [newImage, setNewImage] = useState(null);
  const [imageCaption, setImageCaption] = useState("");
  const [enableEdit, setEnableEdit] = useState(false);
  const [prevImage, setPrevImage] = useState("");
  const { blogId } = useParams();
  const { item, setDeletedItem, setItemLoading, index } = props;

  const handleImageChange = (image) => {
    setNewImage(image);
    console.log("image", image);
    setPrevImage("");
    setImageCaption(item.caption ? item.caption : "");
  };

  useEffect(() => {
    if (item && item.value !== "") {
      storage
        .ref(item.value)
        .getDownloadURL()
        .then(function (downloadURL) {
          console.log("Got download url", downloadURL);
          setPrevImage(downloadURL);
          setImageCaption(item.caption ? item.caption : "");
        });
    } else {
      setEnableEdit(true);
    }
  }, [item]);
  const handleSaveClick = () => {
    if (item) {
      if (newImage) {
        setItemLoading(true);
        const storageRef = storage.ref();
        let imageRef = storageRef.child(`images/${blogId}/${newImage.name}`);
        imageRef
          .put(newImage)
          .then(function (snapshot) {
            database
              .ref("blogs/" + blogId + "/blogItems/" + index)
              .set({
                id: item.id,
                type: "image",
                value: imageRef.fullPath,
                caption: imageCaption,
              })
              .then((data) => {
                setItemLoading(false);
                setEnableEdit(false);
              })
              .catch((err) => console.log("error creating item", err));
          })
          .catch((err) => console.log("error putting file", err));
      } else {
        setItemLoading(true);
        database
          .ref("blogs/" + blogId + "/blogItems/" + index)
          .set({
            id: item.id,
            type: "image",
            value: item.value,
            caption: imageCaption,
          })
          .then((data) => {
            setItemLoading(false);
            setEnableEdit(false);
          })
          .catch((err) => console.log("error creating item", err));
      }
    }
  };
  return (
    <div className={classes.root}>
      {enableEdit ? (
        <React.Fragment>
          <BootstrapInput
            className={classes.controllerBox}
            value={imageCaption}
            onChange={(e) => setImageCaption(e.target.value)}
            fullWidth
            placeholder="Image Caption..."
          />
          <ImageUploader
            prevImage={prevImage}
            onImageSelect={handleImageChange}
          />
          <Box
            display="flex"
            justifyContent="flex-end"
            className={classes.controllerBox}
          >
            <IconButton
              aria-label="done button"
              className={classes.doneIcon}
              onClick={handleSaveClick}
            >
              <DoneIcon />
            </IconButton>
            <IconButton
              aria-label="cancel button"
              className={classes.cancelIcon}
              onClick={() => setEnableEdit(false)}
            >
              <CancelIcon />
            </IconButton>
            <IconButton
              aria-label="delete button"
              className={classes.deleteIcon}
              onClick={() => setDeletedItem(item)}
            >
              <DeleteIcon />
            </IconButton>
          </Box>
        </React.Fragment>
      ) : (
        <Paper className={classes.controllerBox}>
          <Box display="flex" justifyContent="flex-end">
            <IconButton
              aria-label="edit button"
              onClick={() => setEnableEdit(true)}
            >
              <EditIcon color="secondary" />
            </IconButton>
          </Box>
          <Typography align="center" variant="body1">
            {imageCaption}
          </Typography>
          <NormalImage
            src={prevImage}
            alt={item.caption ? item.caption : "some image"}
            style={{ width: "100%" }}
          />
        </Paper>
      )}
    </div>
  );
}

ImageItem.propTypes = {
  setDeletedItem: PropTypes.func.isRequired,
  setItemLoading: PropTypes.func.isRequired,
  item: PropTypes.any.isRequired,
  index: PropTypes.number.isRequired,
};
