import React, { useState, useEffect } from "react";
import { database } from "config/fire-config";
import FullPageLoading from "components/loading/FullPageLoading";
import Grid from "@material-ui/core/Grid";
import BlogCard from "components/BlogCard";
import { makeStyles } from "@material-ui/core/styles";
import { useHistory } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  root: {
    margin: theme.spacing(2),
  },
}));

export default function BlogsList() {
  const classes = useStyles();
  const history = useHistory();
  const [blogs, setBlogs] = useState([]);
  const [blogsLoading, setBlogsLoading] = useState(false);
  const keys = Object.keys(blogs);
  useEffect(() => {
    setBlogsLoading(true);
    let blogsRef = database.ref("blogs");

    let blogsData = blogsRef.on("value", function (snap) {
      if (snap.val() === null || snap.val() === undefined) {
        setBlogsLoading(false);
        setBlogs([]);
      } else {
        setBlogsLoading(false);
        setBlogs(snap.val());
      }
    });
    return () => {
      blogsRef.off("value", blogsData);
    };
  }, []);

  function getBlogsList() {
    let blogCards = [];
    if (keys && keys.length > 0) {
      blogCards = keys.map((key, index) => (
        <Grid key={`blog-${index}`} item xs={12} sm={6} md={4} lg={3} xl={3}>
          <BlogCard
            blog={blogs[key]}
            onClick={() => history.push(`/admin/blogs/${key}`)}
          />
        </Grid>
      ));
    }
    return <React.Fragment>{blogCards}</React.Fragment>;
  }

  return (
    <div className={classes.root}>
      {blogsLoading && <FullPageLoading />}
      <Grid container spacing={2}>
        {getBlogsList()}
      </Grid>
    </div>
  );
}
