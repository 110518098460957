import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import grey from '@material-ui/core/colors/grey';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 0,
    flexShrink: 0,
    borderRadius: 10,
    textTransform: 'none',
    fontFamily: 'Alegreya Sans SC',
    fontSize: '18px',
    height: 42,
    minWidth: 42,
    whiteSpace: 'nowrap',
    padding: theme.spacing(2),
    [theme.breakpoints.down('sm')]: {
      fontSize: '20px',
      height: 40,
      minWidth: 40,
    },
  },
  rounded: {
    borderRadius: 10,
    padding: theme.spacing(0.5),
  },
}));

const CustomButton = (props) => {
  const { className, variant, color, shape, children, style, ...rest } = props;

  const classes = useStyles();

  const rootClassName = clsx(
    {
      [classes.root]: true,
      [classes.rounded]: shape === 'rounded',
    },
    className,
  );

  const finalStyle = { ...style };

  if (variant === 'contained') {
    finalStyle.backgroundColor = color;
    finalStyle.color = '#FFF';
  } else {
    finalStyle.border = `3px solid ${color}`;
    finalStyle.color = color;
  }

  return (
    <Button {...rest} className={rootClassName} style={finalStyle}>
      {children}
    </Button>
  );
};

CustomButton.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  color: PropTypes.string,
  shape: PropTypes.oneOf(['square', 'rounded']),
  style: PropTypes.object,
  variant: PropTypes.oneOf(['contained', 'outlined']),
};

CustomButton.defaultProps = {
  style: {},
  color: grey[600],
  variant: 'contained',
  shape: 'square',
};

export default CustomButton;
