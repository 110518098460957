import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import PropTypes from 'prop-types';
import Title from 'components/Title';
const useStyles = makeStyles((theme) => ({
  root: {
    display: 'grid',
    placeItems: 'center',
    // minHeight: '40vh',
    margin: theme.spacing(1, 0),
  },
  para: {
    fontSize: 22,
    [theme.breakpoints.down('xs')]: {
      width: '90%',
      fontSize: 18,
    },
    [theme.breakpoints.up('sm')]: {
      width: '70%',
    },
    [theme.breakpoints.up('lg')]: {
      width: '65%',
    },
    [theme.breakpoints.up('xl')]: {
      width: '40%',
    },
  },
}));

export default function TeamDesc(props) {
  const classes = useStyles();
  const { description } = props;

  return (
    <div className={classes.root}>
      <Box
        display="flex"
        justifyContent="center"
        className={classes.para}
        flexDirection="column"
      >
        <Title dark>Our Team</Title>
        <Typography align="center" variant="body1" style={{ fontSize: 22 }}>
          {description}
        </Typography>
      </Box>
    </div>
  );
}

TeamDesc.propTypes = {
  description: PropTypes.string.isRequired,
};
