import React from "react";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import PropTypes from "prop-types";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";

import { auth } from "config/fire-config";
import NormalImage from "components/NormalImage";
import { useHistory } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
const useStyles = makeStyles((theme) => ({
  appBar: {
    padding: theme.spacing(1),
    display: "flex",
    position: "relative",
  },
  children: {
    marginTop: theme.spacing(3),
  },
}));
export default function Layout(props) {
  const classes = useStyles();
  const { children } = props;
  const history = useHistory();
  const handleSignOutClick = () => {
    auth
      .signOut()
      .then(function () {
        history.push("/admin/login");
      })
      .catch(function (error) {
        // An error happened.
      });
  };
  return (
    <div>
      <AppBar
        color="secondary"
        className={classes.appBar}
        classes={{ root: classes.appBar }}
      >
        <NormalImage
          src={"/images/logo/251logo.svg"}
          alt="251 logo"
          style={{ position: "absolute", width: "50px", top: "20px" }}
        />
        <Box display="flex" justifyContent="flex-start">
          <div style={{ flexGrow: 1 }}></div>
          <Button
            startIcon={<ExitToAppIcon />}
            color="primary"
            onClick={handleSignOutClick}
          >
            Log out
          </Button>
        </Box>
      </AppBar>
      <div className={classes.children}>{children}</div>
    </div>
  );
}

Layout.propTypes = {
  children: PropTypes.any,
};
