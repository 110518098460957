import React from 'react';
import Header from './header/Header';
import Footer from './footer/Footer';
import PropTypes from 'prop-types';

//Material Ui
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    height: '100vh',
  },
  headerWrapper: {
    flexGrow: 1,
  },
}));

const Layout = (props) => {
  const { children } = props;
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <div className={classes.headerWrapper}>
        <Header>
          <div>{children}</div>
        </Header>
      </div>
      <Footer />
    </div>
  );
};
Layout.propTypes = {
  children: PropTypes.any,
};

export default Layout;
