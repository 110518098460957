import React from 'react';
import BlogStarter from './BlogStarter';
import BlogList from 'features/admin/blog/BlogsList';

export default function AdminHome () {
  return (
    <div>
      <BlogStarter />
      <BlogList />
    </div>
  );
}
