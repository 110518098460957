import React, { useEffect, useState } from "react";
import dayjs from "dayjs";
import PropTypes from "prop-types";
import relativeTime from "dayjs/plugin/relativeTime";

//Data Query
import { storage } from "config/fire-config";

//Component
import NormalImage from "components/NormalImage";

//Material ui
import { makeStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import Typography from "@material-ui/core/Typography";
import CardContent from "@material-ui/core/CardContent";
import Link from "@material-ui/core/Link";

const useStyles = makeStyles((theme) => ({
  card: {
    height: 360,
    // paddingBottom: theme.spacing(2),
  },
  img: {
    objectFit: "cover",
    width: "100%",
    height: 210,
    [theme.breakpoints.down("xs")]: {
      height: 150,
    },
  },
  title: {
    fontSize: "20px",
    fontWeight: "bolder",
    lineHeight: "115%",
    "&:hover": {
      textDecoration: "underline",
    },
  },
  subtitle: {
    fontWeight: 450,
    fontSize: "12px",
  },
  link: {
    cursor: "pointer",
    textDecoration: "none!important",
  },
}));

const BlogCard = (props) => {
  const classes = useStyles();
  const { blog, onClick } = props;
  dayjs.extend(relativeTime);
  const [img, setImg] = useState(null);
  const [caption, setCaption] = useState("");

  const getImg = () => {
    let pic = null;
    if (blog && blog["blogItems"] !== undefined) {
      pic = blog.blogItems.find((element) => {
        return element.type === "image";
      });
      if (pic && pic.value !== "") {
        pic.caption ? setCaption(pic.caption) : setCaption("image");
        storage
          .ref(pic.value)
          .getDownloadURL()
          .then(function (downloadURL) {
            setImg(downloadURL);
          });
      }
    }
  };

  useEffect(() => {
    getImg();
  }, [blog]);

  return (
    blog && (
      <Link onClick={onClick} className={classes.link}>
        <Card className={classes.card} elevation={3}>
          <NormalImage
            src={`${img}`}
            alt={`${caption}`}
            className={classes.img}
          />
          <CardContent>
            <Typography className={classes.title}>{blog.meta.title}</Typography>
            <Typography
              className={classes.subtitle}
              style={{ marginTop: "10px" }}
            >{`Posted ${dayjs(blog.meta.lastUpdated).fromNow("dd")} ago - ${
              blog.meta.timeToRead ? blog.meta.timeToRead : "a few"
            } min read`}</Typography>
            <Typography
              className={classes.subtitle}
            >{`By ${blog.meta.writterName}`}</Typography>
          </CardContent>
        </Card>
      </Link>
    )
  );
};

BlogCard.propTypes = {
  blog: PropTypes.object,
  onClick: PropTypes.func,
};

export default BlogCard;
