import React from 'react';
import PropTypes from 'prop-types';

//Material ui
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';

const useStyles = makeStyles((theme) => ({
  root: {
    position: 'relative',
    // [theme.breakpoints.down('md')]: {
    //   marginTop: theme.spacing(1),
    // },
    // [theme.breakpoints.down('sm')]: {
    //   marginTop: theme.spacing(4),
    // },

    // [theme.breakpoints.down('xs')]: {
    //   marginTop: theme.spacing(3),
    // },
  },
  img: {
    // position: 'absolute',
    zIndex: -2,
    height: '60vh',
    filter: 'contrast(50%)',
    width: '100%',
    backgroundPosition: '0 -50px',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    [theme.breakpoints.down('md')]: {
      height: '38vh',
    },
    [theme.breakpoints.down('xs')]: {
      backgroundPosition: '0 0px',
    },
  },
  layer: {
    position: 'absolute',
    height: '100%',
    width: '100%',

    zIndex: 1,
  },
  title: {
    fontSize: '50px',
    fontFamily: 'Unna',
    [theme.breakpoints.down('md')]: {
      fontSize: '45px',
    },
    [theme.breakpoints.down('sm')]: {
      fontSize: '38px',
    },

    [theme.breakpoints.down('xs')]: {
      fontSize: '30px',
    },
  },
}));
const ProjectHeader = (props) => {
  const classes = useStyles();
  const { projectData } = props;

  return (
    <div className={classes.root}>
      <div
        className={classes.layer}
        style={{
          background: `${
            projectData && projectData.layerColor
              ? projectData.layerColor
              : '#000'
          }`,
        }}
      ></div>
      <div
        className={classes.img}
        style={{
          backgroundImage: `url(${projectData ? projectData.img : ''})  `,
        }}
      />
      <Box
        style={{
          position: 'absolute',
          zIndex: 3,
          left: '50%',
          top: '50%',
          transform: 'translate(-50%, -65%)',
        }}
      >
        {' '}
        <Typography
          className={classes.title}
          style={{
            color: `${
              projectData && projectData.textColor
                ? projectData.textColor
                : '#fff'
            }`,
          }}
        >
          {projectData ? projectData.title : 'Loading...'}
        </Typography>
      </Box>
    </div>
  );
};

ProjectHeader.propTypes = {
  projectData: PropTypes.object,
};

export default ProjectHeader;
