import React, { useState } from 'react';

import IconButton from '@material-ui/core/IconButton';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

const useStyles = makeStyles((theme) => ({
  root: {
    margin: theme.spacing(3),
    // background: '#F8F8F9',
  },
  summaryBox: {
    display: 'flex',
    cursor: 'pointer',
  },
  activeSummary: {
    color: theme.palette.primary.main,
    textShadow: '0.5px 0.5px gray',
    flexGrow: 1,
    fontWeight: 'w600',
  },
  inActiveSummary: {
    color: '#000',
    flexGrow: 1,
  },
  activeDesc: {
    marginTop: theme.spacing(2),
    color: '#000',
    fontSize: 24,
    display: 'block',
  },
  inActiveDesc: {
    display: 'none',
  },
  list: {
    fontSize: 18,
    paddingRight: theme.spacing(2),
    color: theme.palette.primary.main,
    padiding: 0,
  },
}));

export default function CustomAccordion(props) {
  const classes = useStyles();
  const [expanded, setExpanded] = useState(false);
  const { summary, details, lists } = props;
  return (
    <ClickAwayListener
      onClickAway={() => {
        setTimeout(() => {
          setExpanded(false);
        }, 50);
      }}
    >
      <div className={classes.root}>
        <div
          className={classes.summaryBox}
          onClick={() => setExpanded(!expanded)}
        >
          <Typography
            variant="h4"
            className={
              expanded ? classes.activeSummary : classes.inActiveSummary
            }
            style={{ marginTop: '10px' }}
          >
            {summary}
          </Typography>
          <IconButton
            aria-label={`${summary} button`}
            onClick={() => setExpanded(!expanded)}
            size="medium"
          >
            {expanded ? (
              <ExpandMoreIcon
                fontSize="large"
                className={
                  expanded ? classes.activeSummary : classes.inActiveSummary
                }
                style={{ paddingTop: '-15px' }}
              />
            ) : (
              <ChevronRightIcon
                fontSize="large"
                className={
                  expanded ? classes.activeSummary : classes.inActiveSummary
                }
              />
            )}
          </IconButton>
        </div>

        <div className={expanded ? classes.activeDesc : classes.inActiveDesc}>
          <Typography variant="body1" style={{ marginBottom: '12px' }}>
            {details}
          </Typography>

          <ul style={{ lineHeight: '180%' }}>
            {lists.map((listItem, index) => (
              <li key={`List-${index}`} className={classes.list}>
                <span style={{ color: '#000' }}>{listItem}</span>
              </li>
            ))}
          </ul>
        </div>
      </div>
    </ClickAwayListener>
  );
}

CustomAccordion.propTypes = {
  summary: PropTypes.string.isRequired,
  details: PropTypes.string,
  lists: PropTypes.array,
};
