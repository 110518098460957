import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import PropTypes from "prop-types";
import { auth } from "config/fire-config";
import CustomLoading from "components/loading/CustomLoading";

export default function WithAuth(props) {
  const { children } = props;
  const history = useHistory();
  const [currentUser, setCurrentUser] = useState(null);
  useEffect(() => {
    auth.onAuthStateChanged(function (user) {
      if (user) {
        setCurrentUser(user);
        // console.log('user', user);
      } else {
        setCurrentUser(null);
        history.push("/admin/login");
      }
    });
  }, []);
  return <div>{currentUser ? children : <CustomLoading />}</div>;
}

WithAuth.propTypes = {
  children: PropTypes.any,
};
