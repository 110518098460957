const about = {
  whoWeAre: [
    `So much of marketing today falls flat and misses the point, which is the point of sale, the point where consumers engage and the buying decision gets made. So, we’re obsessed with unearthing insights and discovering new brand dimensions. Why? Because insights provide inspiration.`,
    `At 251, we believe in innovative thinking. We work passionately, taking a unique and creative approach to every project. Since 2010, we have delivered integrated solutions tailored to the specific need of each client creating real and effective brand value.
  `,
  ],
  description:
    "We have an open office concept that welcomes a family-like atmosphere. Since our inception, we have adopted a very hybrid working environment. There are practically no job titles (only there for you to identify us). Everyone works together to the culmination of each and every project!",
  teamMembers: [
    {
      fullName: "Addis Alemayehou",
      position: "CEO",
      firstPicture: "/team/addis.jpg",
      secondPicture: "/team/addis-hover.jpg",
    },
    {
      fullName: "Blen Lersachew",
      position: "COO",
      firstPicture: "/team/blen.jpg",
      secondPicture: "/team/blen-hover.jpg",
    },
    {
      fullName: "Mahlet Negussie",
      position: "Finance Manager",
      firstPicture: "/team/mahlet.jpg",
      secondPicture: "/team/mahlet-hover.jpg",
    },
    {
      fullName: "Ejigu Philipos",
      position: "Research Manager",
      firstPicture: "/team/ejigu.jpg",
      secondPicture: "/team/ejigu-hover.jpg",
    },
    {
      fullName: "Robel Wubishet",
      position: "Creative Manager",
      firstPicture: "/team/robel.jpg",
      secondPicture: "/team/robel-hover.jpg",
    },
  ],
};

export default about;
